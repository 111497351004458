<template>
  <div class="NewsDetails">
    <headers></headers>
    <div class="layer1">
      <div class="bg" v-show="layer1Type == 2">
        <img class="bgImg" :src="bg" alt="" />
      </div>
      <div class="tv" v-show="layer1Type == 1">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="tv"
        ></iframe>
      </div>
    </div>
    <div class="layer2">
      <div class="path">
        <span>新闻中心</span><i class="el-icon-arrow-right"></i
        ><span>全文</span>
      </div>
      <div class="article">
        <div class="article_header">
          <h3 class="title">{{ title }}</h3>
          <div class="info">
            <span class="publisher">深圳市云视空间科技有限公司</span>
            <span class="date">{{ date }}</span>
          </div>
        </div>
        <div class="article_body">
          <img
            class="article_img"
            :src="'http://' + img.substring(13)"
            alt=""
          />
          <div class="text" v-html="content"></div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { selectNewsDetails } from '../../api/news.js'
export default {
  components: {
    headers,
    footers
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      title: '',
      content: '',
      date: '',
      img: ''
    }
  },
  mounted () {
    this.nowId = parseInt(window.localStorage.getItem('current_id'))
    this.init()
    this.init2()
  },
  methods: {
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[6].type
      console.log('this.layer1Type', this.layer1Type)
      setTimeout(() => {
        switch (res.data.data.data[6].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[6].img_address.substring(13)
            console.log('当前图片' + this.bg)
            break
          case 1:
            this.tv = res.data.data.data[6].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      let res = await selectNewsDetails({
        id: this.nowId
      })
      let info = res.data.data
      this.title = info.title
      this.date = info.create_time
      this.content = info.desc
      this.img = info.img
      console.log(res)
    }
  }
}
</script>

<style lang="less" scoped>
.NewsDetails {
  width: 100%;
  height: 100%;
  background: #f0f6ff;
  .layer1 {
    width: 100%;
    height: 350px;
    background: #f0f6ff;
    margin-bottom: 30px;
    .bg,
    .tv {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .layer2 {
    width: 1400px;
    margin: 0 auto;
    .path {
      margin-bottom: 27px;
    }
    .article {
      width: 100%;
      .article_header {
        margin-bottom: 28px;
        .title {
          font-size: 22px;
          font-family: AlibabaPuHuiTiM;
          font-weight: 400;
          color: #4486ff;
          text-align: center;
          margin-bottom: 27px;
        }
        .info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .publisher,
          .date {
            font-size: 16px;
            font-family: AlibabaPuHuiTiR;
            font-weight: 400;
            color: #6c7ba8;
          }
        }
      }
      .article_body {
        .article_img {
          display: block;
          width: 956px;
          height: 640px;
          margin: 0 auto;
          margin-bottom: 30px;
        }
        /deep/ .text {
          font-size: 18px;
          font-family: AlibabaPuHuiTiR;
          font-weight: 400;
          color: #6c7ba8;
          white-space: pre-wrap;
          p {
            width: 100%;
            display: block;
            margin-bottom: 25px !important;
          }
        }
      }
    }
  }
}
</style>
