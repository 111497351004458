<template>
  <div class="AR_Application_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        src="https://720yun.com/t/1evkt7dqp1l?scene_id=73707514"
      ></iframe>
      <div class="box">
        <h2>AR整体解决方案</h2>
        <p>AR名片/AR/房产/AR/录取通知书</p>
      </div>
    </div>
    <div class="layer1" v-show="type == 2">
      <div class="box">
        <h2>AR整体解决方案</h2>
        <p>AR名片/AR/房产/AR/录取通知书</p>
      </div>
    </div>
    <div class="layer2">
      <ul class="nav">
        <li v-for="(item, index) in List" :key="index">
          <h2>{{ item.title }}</h2>
          <img :src="'http://' + item.img_address.substring(13)" alt="" />
          <p>
            {{ item.desc }}
          </p>
        </li>
      </ul>
    </div>
    <footerPe></footerPe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerPe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { selectAR } from '../../api/ar.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerPe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      type: '',
      img: '',
      tv: '',
      List: '',
      // tdk信息
      tdks: ''
    }
  },
  methods: {
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[4].type
      this.img = 'http://' + res.data.data.data[4].img_address.substring(13)
      this.tv = res.data.data.data[4].url_address

      console.log('nowType', this.type)

      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await selectAR({})
      console.log('init2', res)
      this.List = res.data.data.data
    },
    async getTdk () {
      let res = await tdk({
        name: 'AR应用'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    }
  },
  mounted () {
    this.getTdk()
    this.init1()
    this.init2()
  }
}
</script>

<style lang="less" scoped>
.AR_Application_PE {
  .layer1_tv {
    iframe {
      width: 100%;
      height: 100%;
    }
    width: 100%;
    height: 210px;
    position: relative;
    .box {
      position: absolute;
      top: 60px;
      left: 40px;
      width: 200px;
      height: 50px;
      color: #fff;
      h2 {
        font-size: 15px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .layer1 {
    width: 100%;
    height: 210px;
    background: url('../../assets/PE_IMG/ar/u219.png');
    background-size: 100% 100%;
    position: relative;
    .box {
      position: absolute;
      top: 60px;
      left: 40px;
      width: 200px;
      height: 50px;
      color: #fff;
      h2 {
        font-size: 15px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .layer2 {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    .nav {
      li {
        margin-bottom: 10px;
        h2 {
          text-align: center;
          font-size: 13px;
          margin-bottom: 10px;
        }
        img {
          display: block;
          margin: 0 auto;
          width: 150px;
          height: 150px;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
