<template>
  <div class="NewsCenter">
    <headers></headers>
    <div class="layer1">
      <div class="bg" v-show="layer1Type == 2">
        <img class="bgImg" :src="bg" alt="" />
      </div>
      <div class="tv" v-show="layer1Type == 1">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="tv"
        ></iframe>
      </div>
    </div>
    <ul class="layer2">
      <li v-for="(item, index) in newsList" :key="index">
        <img
          class="left"
          :src="'http://' + item.img_address.substring(13)"
          alt=""
        />
        <div class="right">
          <h2 class="title" @click="toDetails(item.id)">{{ item.title }}</h2>
          <p
            class="introduce"
            v-html="item.desc"
            style="pointer-events:none;"
          ></p>
          <div class="date">{{ item.crated_time }}</div>
        </div>
      </li>
    </ul>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { select } from '../../api/news.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      // 当前第几页
      currentPage: 0,
      // 页容量
      pageSize: 6,
      // 总共多少条数据
      total: 0,
      newsList: []
    }
  },
  mounted () {
    this.init(), this.init2(), this.getTdk()
  },
  methods: {
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[6].type
      console.log('this.layer1Type', this.layer1Type)
      setTimeout(() => {
        switch (res.data.data.data[6].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[6].img_address.substring(13)
            console.log('当前图片' + this.bg)
            break
          case 1:
            this.tv = res.data.data.data[6].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      let res = await select({
        page: this.currentPage,
        page_size: this.pageSize
      })
      this.newsList = res.data.data.data
      this.total = res.data.data.page_count
      console.log('this.newsList', res.data.data.page_count)
    },
    toDetails (id) {
      window.localStorage.setItem('current_id', id)
      this.$router.push('/newsdetails')
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.init2()
    },
    async getTdk () {
      let res = await tdk({
        name: '新闻中心'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    }
  }
}
</script>

<style lang="less" scoped>
.NewsCenter {
  width: 100%;
  height: 100%;
  background: #f0f6ff;
  .layer1 {
    width: 100%;
    height: 350px;
    background: #f0f6ff;
    margin-bottom: 40px;
    .bg,
    .tv {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .layer2 {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 40px;
    li {
      display: flex;
      padding: 28px 20px;
      border-bottom: 1px solid #c8d2f0;

      &:last-child {
        border: 0;
      }
      .left {
        margin-right: 53px;
        min-width: 380px;
        max-width: 380px;
        height: 240px;
      }
      .right {
        padding-top: 30px;
        .title {
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #4486ff;
          line-height: 41px;
          cursor: pointer;
        }
        .introduce {
          font-size: 16px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #6c7ba8;
          line-height: 24px;
          overflow: hidden; /*必须结合的属性,当内容溢出元素框时发生的事情*/
          text-overflow: ellipsis; /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
          display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
          -webkit-line-clamp: 2; /*用来限制在一个块元素显示的文本的行数。*/
          -webkit-box-orient: vertical; /*必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 。*/
          margin-bottom: 45px;
        }
        .date {
          text-align: right;
          font-size: 16px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #8592b7;
          line-height: 24px;
        }
      }
    }
  }
  .block {
    width: 400px;
    padding-bottom: 50px;
    margin: 0 auto;
    /deep/ .el-pagination {
      .el-pager {
        li {
          background-color: transparent;
        }
      }
      .btn-next,
      .btn-prev {
        background-color: transparent;
      }
    }
  }
}
</style>
