<template>
  <div class="footerPe">
      <div class="top">
        <div class="left">
          <p>咨询热线：15112411921</p>
          <p>电子邮箱：penngwei@yskj3d.com</p>
          <p>联系地址：深圳市龙岗区龙城街道花样年龙年广场2401室</p>
        </div>
        <div class="right">
          <img src="@/assets/PE_IMG/官网首页/u56.png" alt="" />
          <p>微信扫描关注</p>
        </div>
      </div>
      <div class="bottom" @click="goToPage()">
        <p>粤ICP备2021013356号 </p>
        <p>深圳市云视空间科技有限公司 版</p>
      </div>
    </div>
</template>

<script>
export default {
methods:{
	//跳转新链接
	goToPage(){
		window.open('https://beian.miit.gov.cn/','_blank');
	}
}
}
</script>

<style lang='less' scoped>
  .footerPe {
    background-color: #252d44;
    width: 100%;
    height: 188px;
    padding: 15px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 250px;
        height: 60px;
        p {
          font-family: '微软雅黑', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 12px;
          color: #fff;
        }
      }
      .right {
        img {
          width: 70px;
          height: 70px;
        }
        p {
          font-size: 12px;
          color: #fff;
        }
      }
    }
    .bottom {
      font-size: 12px;
      color: #fff;
      text-align: center;
      margin-top:25px;
    }
  }
</style>
