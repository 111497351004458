<template>
  <div class="VR_Panoramic_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        :src="tv"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <div class="layer2">
      <ul class="nav">
        <li v-for="(item, index) in List" :key="index">
          <div class="icon">{{ index + 1 }}</div>
          <h2 class="title">{{ item.title }}</h2>
          <img :src="'http://' + item.img_address.substring(13)" alt="" />
          <p>{{ item.desc }}</p>
        </li>
      </ul>
      <div class="case">
        <h2 class="title">
          精选案例
        </h2>
        <div class="box">
          Selected case
        </div>
      </div>
      <ul class="nav2">
        <li v-for="(item,index) in TvList" :key="index">
          <div class="room">
            <iframe
              id="u289_input"
              scrolling="auto"
              frameborder="1"
              webkitallowfullscreen=""
              mozallowfullscreen=""
              allowfullscreen=""
              :src="item.url_address"
            ></iframe>
          </div>
          <p @click="tohere(item.url_address)">{{item.title}}</p>
        </li>

      </ul>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { selectIntroduce, selectPosters } from '../../api/vr.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      type: '',
      img: '',
      tv: '',
      List: '',
      TvList:'',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.getTdk()
    this.init1()
    this.init2()
    this.init3()
  },
  methods: {
     async getTdk () {
      let res = await tdk({
        name: 'VR全景'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      this.type = res.data.data.data[3].type
      this.img = 'http://' + res.data.data.data[3].img_address.substring(13)
      this.tv = res.data.data.data[3].url_address

      console.log('nowType', this.type)

      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await selectIntroduce({})
      this.List = res.data.data.data
    },
    async init3 () {
      let res = await selectPosters({})
      console.log('init3',res);
      this.TvList=res.data.data.data
    },
    tohere(url){
      window.location.href=url;
    }
  }
}
</script>

<style lang="less" scoped>
.VR_Panoramic_PE {
  .layer1_tv {
    width: 100%;
    height: 210px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .layer1 {
    width: 100%;
    height: 210px;
  }
  .layer2 {
    width: 100%;
    .nav {
      width: 245px;
      margin: 0 auto;
      li {
        margin-top: 15px;
        margin-bottom: 20px;
        position: relative;
        .icon {
          position: absolute;
          top: -2.5px;
          left: -30px;
          width: 25px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          background: url('../../assets/PE_IMG/vr/u264.svg');
          background-size: 100% 100%;
          font-size: 12px;
          color: #fff;
        }
        .title {
          font-size: 13px;
          margin-bottom: 10px;
        }
        img {
          width: 180px;
          height: 100px;
          display: block;
          margin: 0 auto;
        }
        p {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
    .case {
      width: 133px;
      height: 50px;
      background: url('../../assets/PE_IMG/vr/u283.svg');
      margin: 0 auto;
      margin-bottom: 30px;
      position: relative;
      .title {
        font-size: 13px;
        text-align: center;
        line-height: 40px;
      }
      .box {
        width: 80px;
        height: 30px;
        position: absolute;
        left: 50%;
        bottom: -15px;
        margin-left: -40px;
        text-align: center;
        background-color: #ffab58;
        font-size: 13px;
      }
    }
    .nav2 {
      padding-left: 35px;
      padding-right: 35px;
      li {
        width: 100%;

        .room {
          width: 300px;
          height: 170px;
          background: #000;
          margin: 0 auto;
          #u289_input {
            width: 100%;
            height: 100%;
          }
        }
        p {
          text-align: center;
          font-size: 13px;
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
