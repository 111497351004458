<template>
  <div class="VirtualGallery_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        src="https://720yun.com/t/1evkt7dqp1l?scene_id=73707514"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <ul class="layer2">
      <li v-for="(item, index) in List" :key="index">
        <div class="title">{{ item.title }}</div>
        <img :src="'http://' + item.img_address.substring(13)" alt="" />
        <p class="introduce">
          {{ item.desc }}
        </p>
      </li>
    </ul>
    <div class="layer3">
      <div id="top"></div>
      <div class="content">
        <div class="title">展厅类型</div>
        <ul class="nav">
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u102.png" alt="" />
            <p>企业科技馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u103.png" alt="" />
            <p>党建馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u106.png" alt="" />
            <p>书画馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u107.png" alt="" />
            <p>博物馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u110.png" alt="" />
            <p>校史馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u111.png" alt="" />
            <p>纪念馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u114.png" alt="" />
            <p>文旅馆</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u115.png" alt="" />
            <p>直播间</p>
          </li>
          <li>
            <img src="../../assets/PE_IMG/虚拟展厅/u118.png" alt="" />
            <p>特色主题馆</p>
          </li>
        </ul>
      </div>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { selectIntroduce, selectPosters } from '../../api/virtualGallery'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      img: '',
      tv: '',
      type: '',
      List: '',
      bg: '',
      // tdk信息
      tdks: ''
    }
  },
  methods: {
    async init1 () {
      let res = await selectNavBarAndBanner({})
      this.img = 'http://' + res.data.data.data[1].img_address.substring(13)
      this.tv = res.data.data.data[1].url_address
      this.type = res.data.data.data[1].type
      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await selectIntroduce({})
      console.log('init2', res)
      this.List = res.data.data.data
    },
    async init3 () {
      let res = await selectPosters({})
      console.log('init3', res)
      this.bg = 'http://' + res.data.data.data[0].img_address.substring(13)

      document.getElementById('top').style.background =
        'url(' + this.bg + ') no-repeat' //通过js控制改变行内样式
      document.getElementById('top').style.backgroundSize = '100% 60px'
    },
    async getTdk () {
      let res = await tdk({
        name: '虚拟展厅'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    }
  },
  mounted () {
    this.getTdk()
    this.init1()
    this.init2()
    this.init3()
  }
}
</script>

<style lang="less" scoped>
.VirtualGallery_PE {
  width: 100%;
  .layer1_tv {
    width: 100%;
    height: 210px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .layer1 {
    width: 100%;
    height: 210px;
    #u93_input {
      width: 100%;
      height: 100%;
    }
  }
  .layer2 {
    li {
      padding-left: 30px;
      padding-right: 30px;
      .title {
        font-family: '微软雅黑 Bold', '微软雅黑', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 13px;
        letter-spacing: normal;
        color: #000000;
        text-align: center;
        margin-top: 15px;
      }
      img {
        display: block;
        margin: 0 auto;
        width: 190px;
        height: 110px;
        margin-top: 10px;
      }
      .introduce {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.498039215686275);
        text-align: justify;
        line-height: 28px;
        margin-top: 10px;
      }
    }
  }
  .layer3 {
    width: 100%;
    margin-top: 20px;
    padding-bottom: 10px;
    #top {
      width: 100%;
      height: 60px;
    }
    .content {
      .title {
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: '微软雅黑 Bold', '微软雅黑', sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 13px;
        letter-spacing: normal;
        color: #000000;
        text-align: center;
      }
      .nav {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 150px;
          img {
            width: 150px;
            height: 90px;
          }
          p {
            font-family: '微软雅黑 Bold', '微软雅黑', sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 12px;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
</style>
