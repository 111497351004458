import _fetch from '@/api/request.js'

function select (data) {
  return _fetch({
    url: '/api/v1/tv',
    method: 'post',
    data
  })
}


export {select}
