<template>
  <div class="Home_PE">
    <navbar></navbar>
    <div class="layer1_tv" style="display: flex;" v-show="type == 1">
<!--      <iframe
        id="u254_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        :src="tv"
      ></iframe> -->
			
			<!-- 顶部图 -->
			<div class="" style="display: flex;">
				<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348068660351.jpg" style="width: 100%;height: auto;" @click="goNewPage()">
			</div>
			
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <div class="layer2">
      <div class="mainTitle" style="margin-top: 0;">
        核心业务
      </div>
      <ul class="content" v-for="(item, index) in List" :key="index">
        <li>
          <div class="title">{{ item.title }}</div>
          <img :src="'http://' + item.img_address.substring(13)" alt="" @click="toCoreBusiness(index)"/>
          <p class="introduce">
            {{ item.desc }}
          </p>
        </li>
      </ul>
      <div class="mainTitle">
        公司简介
      </div>
      <div class="CompanyIntroduction">
        深圳市云视空间科技有限公司致力于提供企业的数字化转型整体解决方案。主营虚拟展厅设计、VR全景拍摄、3D数字建模、AR/VR场景应用、视频制作、系统定制化开发等业务。我们的愿景是为全国每一个企业提供定制化数字解决方案，助力企业开启数字化营销新模式。总公司位于广东省深圳市，分公司位于江西省会南昌，并在广州、上海等地设有办事处。凭借着丰富的经验和过硬的制作团队及营销策划团队，为客户提供了一系列高效，高性价比的企业数字化营销解决方案。经过我司技术人员不断努力，发布平台全方位覆盖互联网各主要终端，如手机、平板电脑、PC电脑等，通过QQ、微信、网站等多渠道推广方式，为客户提供多元化的展示方案。
      </div>
	  
	  <div class="mainTitle">
	    荣誉资质
	  </div>
	  <div class="container-fixed stacked-cards stacked-cards-slide">
	  			<ul>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348068985282.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069141913.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069263104.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069390755.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069513416.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069655937.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069811848.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069866819.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/163480700580210.jpg" >
	  				</li>
	  				<li>
	  					<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/163480701163911.jpg" >
	  				</li>
	  			</ul>
	  		</div>
	  
      <div class="mainTitle">
        合作客户
      </div>
      <ul class="cooperation">
        <li v-for="(item, index) in PartnersArr" :key="index">
          <img :src="'http://' + item.img_address.substring(13)" alt="" />
        </li>
      </ul>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
	import $ from 'jquery'
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { getLocal, setLocal } from '../../utils/local.js'
import {stackedCards} from '../../api/stackedCards.min.js'
import {
  selectNavBarAndBanner,
  selectCoreBusiness,
  selectPartners
} from '../../api/home.js'
import { tdk } from '../../api/tdk'

export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      img: '',
      tv: '',
      type: '',
      List: '',
      PartnersArr: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    // if (this._isMobile()) {
    //   //移动端
    //   this.$router.replace('/m')
    // } else {
    //   //PC端
    //   this.$router.replace('/')
    // }

    this.init1()
    this.init2()
    this.init3()
    this.getTdk()
		
		//加载证书动画实例
		var stackedCard = new stackedCards({
		 	selector: '.stacked-cards',
		 	layout: "slide",
		 	transformOrigin: "bottom",
		});
		
		stackedCard.init();
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '首页'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    //判断是移动端还是PC端
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[0].type
      this.img = 'http://' + res.data.data.data[0].img_address.substring(13)
      this.tv = res.data.data.data[0].url_address
      console.log('当前tv', this.tv)
      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await selectCoreBusiness({})
      // console.log('init2', res)
      this.List = res.data.data.data
    },
    //查询合作伙伴
    async init3 () {
      let res = await selectPartners({})
      console.log('init3', res)
      this.PartnersArr = res.data.data.data
    },
		
		
		
		toCoreBusiness (index) {
		  switch (index) {
		    case 0:
		      this.$store.commit('setCurrentIndex',1)
		      this.to('/virtualGallery_pe')
		      break
		    case 1:
		      this.$store.commit('setCurrentIndex',4)
		      this.to('/vr_panoramic_pe')
		      break
		    case 2:
		      this.$store.commit('setCurrentIndex',5)
		      this.to('/videoproduction_pe')
		      break
			 case 3:
			    this.$store.commit('setCurrentIndex',2)
			    this.to('/cloudhall_pe')
			    break
		
		    default:
		      break
		  }
		},
		
		to (path) {
		  this.$router.push(path)
		},
		
		//顶部图跳转链接
		goNewPage(){
				window.open('https://720yun.com/t/39vktmqe719#scene_id=75211688', '_blank');
		},
  }
}
</script>

<style lang="less" scoped>
.Home_PE {
  width: 100%;
  position: relative;
  .layer1 {
    width: 100%;
    height: 210px;
  }
  .layer1_tv {
    width: 100%;
    // height: 210px;
    #u254_input {
      width: 100%;
      height: 100%;
    }
  }
  .layer2 {
    width: 100%;
    .mainTitle {
      height: 50px;
      line-height: 50px;
      text-indent: 15px;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      margin-top: 10px;
      font-family: '微软雅黑 Bold', '微软雅黑', sans-serif;
      font-weight: 700;
      font-style: normal;
      text-align: left;
      font-size: 13px;
    }
    .title {
      font-family: '微软雅黑 Bold', '微软雅黑', sans-serif;
      font-weight: 700;
      font-style: normal;
      text-align: left;
      font-size: 13px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
    }
    .content {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      li {
        width: 100%;
        list-style: none;
        margin-top: 10px;
        img {
          width: 190px;
          height: 110px;
          display: block;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .introduce {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.498039215686275);
          text-align: justify;
          line-height: 21px;
        }
      }
    }
    .CompanyIntroduction {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 12px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.498039215686275);
      margin-top: 10px;
    }
    .cooperation {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      li {
        width: 100px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 100%;
        }
      }
      &::after {
        content: '';
        width: 100px;
        height: 50px;
      }
    }
  }
}

//


.stacked-cards h2 {
			text-align: center;
			position: relative;
			// top: -20px;
		}

		.intro {
			max-width: 1000px;
			margin: 20px auto;
			text-align: center;
		}

		.container-fuild {
			max-width: 80%;
			margin: 0 auto;
		}

		.container-fixed {
			max-width: 1200px;
			margin: 0 auto;
		}

		.divider {
			max-width: 500px;
			margin: 25px auto;
			background-color: #ccc;
			height: 2px;
			width: 100%;
		}

		.stacked-cards {
			// padding-top: 40px;
			padding-top: 5px;
			padding-bottom: 45px;
		}

		.stacked-cards-fanOut {
			padding-bottom: 40px;
		}

		.stacked-cards-fanOut li img {
			max-height: 200px;
		}

		.stacked-cards li {
			width: 100px;
			height: 100px;
		}

		@media (max-width: 767px) {
			.stacked-cards li {
				height: 100px;
			}
		}
		
		.source {
			margin: 25px auto;
		}

		.header {
			margin: 0px auto;
			padding: 25px 5px;
			background-color: #fff;
		}
		.header img {
			display: block;
			margin: 0 auto;
			max-width: 300px;
			height: auto;
		}
</style>
