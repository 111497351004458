import _fetch from '@/api/request.js'

function select1 (data) {
  return _fetch({
    url: '/api/v1/int',
    method: 'post',
    data
  })
}

function select2 (data) {
  return _fetch({
    url: '/api/v1/user',
    method: 'post',
    data
  })
}

export {select1,select2}
