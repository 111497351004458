<template>
  <div class="CloudConventionPC">
    <headers></headers>
    <div class="contents">
      <div class="layer1">
        <div class="bg" v-show="layer1Type == 2">
          <img class="bgImg" :src="bg" alt="" />
        </div>
        <div class="tv" v-show="layer1Type == 1">
          <iframe
            id="u254_input"
            scrolling="auto"
            frameborder="1"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            :src="tv"
          ></iframe>
        </div>
      </div>
      <div class="layer2">
        <div class="content">
          <div class="title">
            <div class="title_main">
              <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
              <h2>云会展优势</h2>
              <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
            </div>
            <h3 class="subtitle">Core business</h3>
          </div>
          <ul class="nav">
            <li>
              <img src="../../assets/img/pc/CloudConvention/1.png" alt="" />
              <div class="introduce">
                <p>精细数字运营，沉淀优质买家</p>
                <p>参展数据统计，复盘商机淘金</p>
              </div>
            </li>
            <li>
              <img src="../../assets/img/pc/CloudConvention/2.png" alt="" />
              <div class="introduce">
                <p>智能匹配搜索，需求精准对接</p>
                <p>深度聚焦行业，优质供求推荐</p>
              </div>
            </li>
            <li>
              <img src="../../assets/img/pc/CloudConvention/3.png" alt="" />
              <div class="introduce">
                <p>视频语音会客，足不出户参展</p>
                <p>产品多维展示，助力订单落地</p>
                <p>来访留言记录，把握每个商机</p>
              </div>
            </li>
            <li>
              <img src="../../assets/img/pc/CloudConvention/4.png" alt="" />
              <div class="introduce">
                <p>全景、视频、图文展示</p>
                <p>上线下多渠道推广</p>
                <p>全供应链贸易综合服务</p>
              </div>
            </li>
            <li>
              <img src="../../assets/img/pc/CloudConvention/5.png" alt="" />
              <div class="introduce">
                <p>三维展示产品细节和企业实力</p>
                <p>即时通讯，随时线上洽谈</p>
                <p>线上会议洽谈，远程如面谈</p>
              </div>
            </li>
          </ul>
					
					<div class="titles">
					  <div class="title_main">
					    <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
					    <h2>案例演示</h2>
					    <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
					  </div>
					  <h3 class="subtitle">Case Studies</h3>
					</div>
					
					<!-- 新增的视频 -->
					<div class="" style="border-radius: 20px;width: 1200px;height: 600px;margin: 0 auto;margin-bottom: 100px;">
						<video preload="auto" src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1634728690754sp.mp4" style="border-radius: 20px;width: 100%;height: 600px;" :controls="aaa"></video>
					</div>
					
					
          <div class="contrast">
            <h2 class="contrastTitle">
              线下展会与云展会对比
            </h2>
            <div class="left">
              <div class="left_content">
                <h2 class="left_content_title">线下展会</h2>
                <div class="left_content_introduce">
                  <p>数百万至数千万</p>
                  <p>绝大部分费用在展会举办前支付</p>
                  <p>广告费、展位费、专业会议费</p>
                  <p>需要花费数月准备，联系多方相关人员</p>
                  <p>每家参展公司只能安排少数销售代表参展</p>
                  <p>展位逐个参观，展位数量多较容易遗忘</p>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="right_content">
                <h2 class="right_content_title">线下展会</h2>
                <div class="right_content_introduce">
                  <p>数百万至数千万</p>
                  <p>绝大部分费用在展会举办前支付</p>
                  <p>广告费、展位费、专业会议费</p>
                  <p>需要花费数月准备，联系多方相关人员</p>
                  <p>每家参展公司只能安排少数销售代表参展</p>
                  <p>展位逐个参观，展位数量多较容易遗忘</p>
                </div>
              </div>
            </div>
          </div>
          <div class="titles">
            <div class="title_main">
              <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
              <h2>行业应用</h2>
              <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
            </div>
            <h3 class="subtitle">Industry Application</h3>
          </div>
          <ul class="choose">
            <li
              v-for="(item, index) in choose"
              :key="index"
              :class="currentIndex == index ? 'active' : ''"
              @click="change(index)"
            >
              {{ item }}
            </li>
          </ul>
          <div class="application">
            <div class="bg">
              <div class="application_title">
                <span class="decoration"></span>
                <h2 v-show="currentIndex == 0">云展示</h2>
                <h2 v-show="currentIndex == 1">云洽谈</h2>
                <h2 v-show="currentIndex == 2">云直播</h2>
              </div>
              <div class="application_content" v-show="currentIndex == 0">
                <p>线上展厅：全方位展示公司产品、技术实力、企业文化等</p>
                <p>产品环物：多维度展示产品细节、外观结构、工作原理等</p>
                <p>
                  真人讲解：展厅内可加入真人讲解音频让浏览者对公司和产品印象更加深刻
                </p>
              </div>
              <div class="application_content2" v-show="currentIndex == 1">
                <p>
                  系统加入了在线洽谈功能，可实现文字、图片、表情、短视频等富媒体消息收发；
                  更采用了全球均可互通的音视频通话技术，在平台上即可实现网上“面对面”沟通，
                  全面满足商家与买家的实时洽谈需求，随时谈生意。
                </p>
              </div>
              <div class="application_content3" v-show="currentIndex == 2">
                <p>
                  平台接入了直播功能，实现开幕式直播，大会直播，展商直播，线上发布会等多种直播形式，
                  帮助客户全面展示企业形象和公司产品。
                </p>
              </div>
            </div>
            <img
              class="leftBg"
              src="../../assets/img/pc/CloudConvention/c1.png"
              alt=""
              v-show="currentIndex == 0"
            />
            <img
              class="leftBg"
              src="../../assets/img/pc/CloudConvention/c2.png"
              alt=""
              v-show="currentIndex == 1"
            />
            <img
              class="leftBg"
              src="../../assets/img/pc/CloudConvention/c3.png"
              alt=""
              v-show="currentIndex == 2"
            />
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      choose: ['云展示', '云洽谈', '云直播'],
      currentIndex: 0,
      // tdk信息
      tdks: '',
	  aaa:false
    }
  },
  mounted () {
    this.getTdk()
    this.init()
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '云会展'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
	  console.log(this.aaa)
	  this.aaa=true
	  console.log(this.aaa)
    },
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[2].type
      setTimeout(() => {
        switch (res.data.data.data[2].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[2].img_address.substring(13)
            break
          case 1:
            this.tv = res.data.data.data[2].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    change (index) {
      this.currentIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.CloudConventionPC {
  .contents {
    width: 100%;
    height: 100%;
    .layer1 {
      width: 100%;
      height: 500px;
      background: #f0f6ff;
      margin-bottom: 60px;
      .bg,
      .tv {
        width: 100%;
        height: 100%;
        iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
        .bgImg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .layer2 {
      .content {
        width: 1400px;
        margin: 0 auto;
        padding-bottom: 44px;
        .title {
          width: 1200px;
          margin: 0 auto;
          padding-bottom: 80px;
          position: relative;
          .title_main {
            display: flex;
            img {
              width: 416px;
              height: 20px;
              margin-top: 40px;
            }
            h2 {
              font-size: 51px;
              font-family: Alibaba PuHuiTi;
              font-weight: bold;
              color: #4c8dfc;
              margin-left: 20px;
              margin-right: 20px;
            }
          }
          .subtitle {
            margin: 0 auto;
            font-size: 22px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #4c8dfc;
            text-align: center;
            letter-spacing: 8px;
            margin-top: 5px;
            position: absolute;
            right: 510px;
          }
        }
        .nav {
          display: flex;
          justify-content: space-between;
          padding-bottom: 92px;
          li {
            list-style: none;
            width: 260px;
            height: 238+46px;
            background: linear-gradient(180deg, #f6faff, #f1f7fe, #f2f8ff);
            box-shadow: 0px 10px 20px 0px rgba(17, 87, 183, 0.1);
            border-radius: 20px;
            position: relative;
            .introduce {
              width: 210px;
              height: 75px;
              margin: 0 auto;
              position: absolute;
              bottom: 20px;
              left: 50%;
              margin-left: -105px;
              p {
                font-size: 16px;
                font-family: Alibaba PuHuiTi;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-bottom: 2.5px;
                text-align: center;
              }
            }
          }
        }
        .contrast {
          display: flex;
          justify-content: space-between;
          position: relative;
          .contrastTitle {
            width: 300px;
            font-size: 30px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #6c7ba8;
            text-align: center;
            position: absolute;
            display: block;
            left: 50%;
            margin-left: -150px;
            margin-top: 30px;
          }
          .left {
            width: 687px;
            height: 510px;
            background: url('../../assets/img/pc/CloudConvention/left.png');
            background-size: 100% 100%;
            .left_content {
              padding-top: 123px;
              padding-right: 21px;
              float: right;
              .left_content_title {
                font-size: 26px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                color: #ffffff;
                text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.35);
                width: 100%;
                text-align: right;
                margin-bottom: 14px;
                padding-right: 18px;
              }
              .left_content_introduce {
                width: 376px;
                height: 185px;
                background: #00000059;
                border-radius: 20px;
                font-size: 16px;
                font-family: Alibaba PuHuiTi;
                font-weight: 500;
                color: #ffffff;
                text-align: right;
                line-height: 27px;
                padding-top: 15px;
                padding-right: 20px;
                padding-bottom: 15px;
              }
            }
          }
          .right {
            width: 687px;
            height: 510px;
            background: url('../../assets/img/pc/CloudConvention/right.png');
            background-size: 100% 100%;
            .right_content {
              padding-top: 123px;
              padding-left: 21px;
              float: left;
              .right_content_title {
                font-size: 26px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                color: #ffffff;
                text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.35);
                margin-bottom: 14px;
                padding-left: 18px;
              }
              .right_content_introduce {
                width: 376px;
                height: 185px;
                background: #00000059;
                border-radius: 20px;
                font-size: 16px;
                font-family: Alibaba PuHuiTi;
                font-weight: 500;
                color: #ffffff;
                line-height: 27px;
                padding-top: 15px;
                padding-left: 20px;
                padding-bottom: 15px;
              }
            }
          }
        }
        .titles {
          width: 1076px;
          margin: 0 auto;
          padding-bottom: 30px;
          .title_main {
            display: flex;
            img {
              width: 416px;
              height: 20px;
              margin-top: 40px;
            }
            h2 {
              font-size: 51px;
              font-family: Alibaba PuHuiTi;
              font-weight: bold;
              color: #4c8dfc;
              margin-left: 20px;
              margin-right: 20px;
            }
          }
          .subtitle {
            font-size: 22px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #4c8dfc;
            text-align: center;
            letter-spacing: 8px;
            margin-top: 5px;
          }
        }
        .choose {
          width: 400px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          padding-bottom: 70px;
          li {
            width: 120px;
            height: 42px;
            line-height: 42px;
            text-align: center;
            list-style: none;
            background: linear-gradient(0deg, #f7faff, #e0ebff, #f9fbff);
            border: 1px solid #dbe7ff;
            box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
            border-radius: 21px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #6c7ba8;
            line-height: 41px;
            cursor: pointer;
          }
          .active {
            background: linear-gradient(0deg, #abd4ff, #76acff, #4783f3);
            font-size: 18px;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #ffffff;
            line-height: 41px;
          }
        }
        .application {
          width: 1400px;
          height: 500px;
          position: relative;
          .bg {
            width: 813px;
            height: 342px;
            background: linear-gradient(-1deg, #eaeef4, #ffffff);
            border: 3px solid #ffffff;
            box-shadow: 0px 17px 29px 0px rgba(9, 20, 65, 0.1);
            border-radius: 50px;
            position: absolute;
            right: 0;
            padding-top: 75px;
            padding-left: 126px;
            .application_title {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .decoration {
                width: 7px;
                height: 28px;
                background: #4c8dfc;
                margin-right: 12px;
              }
              h2 {
                font-size: 30px;
                font-family: Alibaba PuHuiTi;
                font-weight: bold;
                color: #6c7ba8;
              }
            }
            .application_content {
              line-height: 30px;
              font-size: 18px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #6d7382;
            }
            .application_content2 {
              font-size: 18px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #6d7382;
              line-height: 30px;
            }
            .application_content3 {
              font-size: 18px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #6d7382;
              line-height: 30px;
            }
          }
          .leftBg {
            width: 660px;
            height: 328px;
            position: absolute;
            top: 15px;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
