import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //锚点
    mao: 0,
    //菜单当前的index
    currentIndex: 0
  },
  mutations: {
    // 锚点
    setMao (state, data) {
      state.mao = data
    },
    setCurrentIndex (state, data) {
      state.currentIndex = data
    }
  },
  actions: {},
  modules: {}
})
