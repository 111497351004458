<template>
  <div class="footers">
    <div class="content">
      <img
        src="../../assets/img/pc/Home/pclogo.4f407559 拷贝@2x.png"
        alt=""
        class="footersLogo"
      />
      <div class="contact">
        <div class="left"></div>
        <div class="right">
          <p v-if="message.content1">{{message.content1}}</p>
          <p v-if="message.content2">{{message.content2}}</p>
        </div>
      </div>
      <div class="qr_code">
        <div class="item">
          <img src="../../assets/img/pc/Home/微信图片_20210726175254.jpg" alt="" />
          <p>【微信扫码关注】</p>
        </div>
        <div class="item">
          <img src="../../assets/img/pc/Home/图层 509@2x.png" alt="" />
          <p>【微信客服】</p>
        </div>
      </div>
    </div>
    <div class="plateNumber" style="cursor: pointer;" @click="goToPage()">
      粤ICP备2021013356号 深圳市云视空间科技有限公司 版权所有
    </div>
  </div>
</template>

<script>
export default {
	props:{
		message:Object
	},
	methods:{
		//跳转新链接
		goToPage(){
			window.open('https://beian.miit.gov.cn/','_blank');
		}
	}
}
</script>

<style lang="less" scoped>
.footers {
  width: 100%;
  height: 220px;
  background: #414755;
  .content {
    width: 1400px;
    height: 190px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .footersLogo {
      padding-left: 92px;
      padding-right: 88px;
    }
  }
  .plateNumber {
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #343944;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #878ea6;
  }
  .contact {
    display: flex;
    align-items: center;
    padding: 16px 90px;
    border-left: 2px solid #4b5363;
    border-right: 2px solid #4b5363;
    margin-right: 85px;
    .left {
      width: 58px;
      height: 58px;
      margin-right: 17px;
      background: url('../../assets/img/pc/Home/群蜂电话@2x.png');
    }
    .right {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c0c4d0;
    }
  }
  .qr_code {
    display: flex;
    .item {
      margin-right: 80px;
      text-align: center;
      img {
        width: 110px;
        height: 110px;
      }
      p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c0c4d0;
        text-align: center;
      }
    }
  }
}
</style>
