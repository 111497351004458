<template>
  <div class="VideoProduction_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        src="https://720yun.com/t/1evkt7dqp1l?scene_id=73707514"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <div class="layer2">
      <div class="introduce">
        <p>我们拥有专业的技术和丰富经验的团队，可以满足您所有的视频制作求。</p>
        <p>
          我们为您提供全方位的视频制作服务：脚本创作、视频拍摄、后期剪辑、特效设计、旁白配音等。专业视频制作团队，为您打造高端的企业宣传片、三维视频、产品视频、MG动画、短视频等。打通品牌流量入口，轻松获取超级流量，让更多人看到您的品牌。
        </p>
      </div>
      <ul class="nav">
        <li v-for="(item, index) in info" :key="index">
          <div class="icon">{{ index + 1 }}</div>
          <h2>{{ item.title }}</h2>
          <p>{{ item.content }}</p>
          <img :src="item.img" alt="" />
        </li>
      </ul>
      <div class="youCan">
        <div class="title">通过设计有效的公司视频作品，您可以：</div>
        <ul class="list">
          <li>开拓新市场</li>
          <li>提高信誉和参与度</li>
          <li>提高转化率并增加销量</li>
          <li>在简短的视频中讲诉品牌与产品的故事</li>
          <li>在市场竞争的浪潮中脱颖而出</li>
          <img src="" alt="" />
        </ul>
      </div>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { select } from '../../api/videoproduction.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      type: '',
      img: '',
      tv: '',
      TvArr: '',
      info: [
        {
          title: '宣传片',
          content: '高端视频制作，彰显企业品牌形象提升品牌价值,',
          img: require('../../assets/PE_IMG/视频制作/u422.png')
        },
        {
          title: 'MG视频',
          content: '全新视觉演绎，秀出个性与价值，生动有趣的传递视频信息',
          img: require('../../assets/PE_IMG/视频制作/u426.png')
        },
        {
          title: '产品视频',
          content: '静态产品动态展示，提升产品价值，加深客户对产品的认知',
          img: require('../../assets/PE_IMG/视频制作/u430.png')
        },
        {
          title: '短视频',
          content: '抖音、快手短视频制作，酷炫魔性的带货王',
          img: require('../../assets/PE_IMG/视频制作/u434.png')
        },
        {
          title: '三维视频',
          content: '产品三维建模，全方位展现产品结构功能与细节',
          img: require('../../assets/PE_IMG/视频制作/u438.png')
        },
        {
          title: '广告视频',
          content: '准确表达信息，树立品牌形象，高端创意的广告正确引导客户',
          img: require('../../assets/PE_IMG/视频制作/u442.png')
        }
      ],
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init1()
    this.init2()
    this.getTdk()
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '视频制作'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[5].type
      this.img = 'http://' + res.data.data.data[5].img_address.substring(13)
      this.tv = res.data.data.data[5].url_address
      console.log('nowType', this.type)
      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await select({})
      console.log('init2', res.data.data.data)
      this.TvArr = res.data.data.data
    }
  }
}
</script>

<style lang="less" scoped>
.VideoProduction_PE {
  width: 100%;
  .layer1_tv {
    width: 100%;
    height: 210px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .layer1 {
    width: 100%;
    height: 210px;
    background: url('../../assets/PE_IMG/视频制作/u417.jpg');
    background-size: 100% 100%;
  }
  .layer2 {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .introduce {
      margin-bottom: 15px;
      p {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.698039215686274);
        line-height: 24px;
        padding-top: 10px;
      }
    }
    .nav {
      padding-left: 30px;
      padding-right: 30px;
      li {
        position: relative;
        .icon {
          width: 25px;
          height: 25px;
          background: url('../../assets/PE_IMG/vr/u264.svg');
          background-size: 100% 100%;
          position: absolute;
          top: -5px;
          left: -30px;
          text-align: center;
          line-height: 25px;
          color: #fff;
        }
        h2 {
          font-size: 13px;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
        }
        img {
          width: 250px;
          height: 150px;
          display: block;
          margin: 0 auto;
          margin-top: 10px;
          margin-bottom: 20px;
          object-fit: fill;
        }
      }
    }
    .youCan {
      width: 100%;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.749019607843137);
      .list {
        margin-left: 20px;
        margin-bottom: 10px;
        li {
          margin-top: 10px;
          list-style: square;
        }
      }
    }
  }
}
</style>
