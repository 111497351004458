<template>
  <div class="AR_PC">
    <headers></headers>
    <div class="layer1">
      <div class="bg" v-show="layer1Type == 2">
        <img class="bgImg" :src="bg" alt="" />
      </div>
      <div class="tv" v-show="layer1Type == 1">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="tv"
        ></iframe>
      </div>
    </div>
    <div class="layer2">
      <div class="main">
        <div class="content1">
          <img
            class="left"
            :src="'http://'+this.datas[0].img_address.substring(13)"
            alt=""
          />
          <div class="right">
            <h2 class="titles">{{ this.datas[0].title }}</h2>
            <p>
              {{ this.datas[0].desc }}
            </p>
          </div>
        </div>
        <div class="content2">
          <div class="left">
            <h2 class="titles">{{ this.datas[1].title }}</h2>
            <p>
              {{ this.datas[1].desc }}
            </p>
          </div>
          <img :src="'http://'+this.datas[1].img_address.substring(13)" alt="" />
        </div>
        <div class="content1">
          <img
            class="left"
            :src="'http://'+this.datas[2].img_address.substring(13)"
            alt=""
          />
          <div class="right">
            <h2 class="titles">{{ this.datas[2].title }}</h2>
            <p>
              {{ this.datas[2].desc }}
            </p>
          </div>
        </div>
        <div class="content2">
          <div class="left">
            <h2 class="titles">{{ this.datas[3].title }}</h2>
            <p>
              {{ this.datas[3].desc }}
            </p>
          </div>
          <img :src="'http://'+this.datas[3].img_address.substring(13)" alt="" />
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { selectAR } from '../../api/ar.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      datas: [],
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init()
    this.init2()
    this.getTdk()
  },
  methods: {
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[4].type
      console.log('this.layer1Type', this.layer1Type)
      setTimeout(() => {
        switch (res.data.data.data[4].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[4].img_address.substring(13)
            // this.bg ='http://' + this.res.data.data.data[4].img_address.substring(13)
            console.log('当前图片' + this.bg)
            break
          case 1:
            this.tv = res.data.data.data[4].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      let res = await selectAR({})
      console.log('init2', res)
      this.datas = res.data.data.data
    },
    async getTdk () {
      let res = await tdk({
        name: 'AR应用'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
  }
}
</script>

<style lang="less" scoped>
.AR_PC {
  width: 100%;
  height: 100%;
  background: #f0f6ff;
  .layer1 {
    width: 100%;
    height: 594px;
    background: #f0f6ff;
    margin-bottom: 60px;
    .bg,
    .tv {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .layer2 {
    .main {
      width: 1400px;
      margin: 0 auto;
      padding-bottom: 30px;
      .content2 {
        display: flex;
        justify-content: space-between;
        padding: 0 100px;
        margin-bottom: 20px;
        .left {
          width: 550px;
          height: 300px;
          .titles {
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            line-height: 28px;
            margin-top: 56px;
            margin-bottom: 20px;
            text-align: right;
          }
          p {
            font-size: 18px;
            color: #6d7382;
            line-height: 28px;
            text-align: right;
          }
        }
        .right {
          width: 550px;
          height: 300px;
        }
      }
      .content1 {
        display: flex;
        justify-content: space-between;
        padding: 0 100px;
        margin-bottom: 20px;
        .left {
          width: 550px;
          height: 300px;
        }
        .right {
          width: 550px;
          height: 300px;
          .titles {
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            line-height: 28px;
            margin-top: 56px;
            margin-bottom: 20px;
          }
          p {
            font-size: 18px;
            color: #6d7382;
            line-height: 28px;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
