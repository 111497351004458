// 获取
function getLocal(key) {
    return window.localStorage.getItem(key)
}
// 存储
function setLocal(key, value) {
    window.localStorage.setItem(key, value)
}
// 删除
function removeLocal() {
    window.localStorage.removeItem(TOKEN)
}
export { getLocal, setLocal, removeLocal }
