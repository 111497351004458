<template>
  <div class="caseCenter">
    <headers></headers>
    <div class="layer1">
      <div class="bg">
        <img class="bgImg" src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632560503234banner%E5%9B%BE.png"
          alt="" />
      </div>
    </div>
    <div class="layer2">
      <div class="content">
        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>虚拟展厅</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Virtual Showroom</h3>
        </div>
        <!-- 虚拟展厅列表 -->
        <div class="" style="display: flex;justify-content: center;margin-bottom: 45px;">
          <div class="Showroom-box">
            <div class="Showroom" v-for="(item,index) in ShowroomList" :key="index" @click="goPage(item.goUrl)">
              <div class="Showroom-img">
                <img :src="item.image">
              </div>
              <div class="Showroom-title">{{item.title}}</div>
            </div>
            <!-- 更多 -->
            <div class="flex-space-end">更多>></div>
          </div>
        </div>


        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>全景拍摄</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Panorama Shooting</h3>
        </div>
        <!-- 全景拍摄列表 -->
        <div class="" style="display: flex;justify-content: center;margin-bottom: 45px;">
          <div class="Showroom-box">
            <div class="Showroom" v-for="(item,index) in ShootingList" :key="index"  @click="goPage(item.goUrl)">
              <div class="Showroom-img">
                <img :src="item.image">
              </div>
              <div class="Showroom-title">{{item.title}}</div>
            </div>
            <!-- 更多 -->
            <div class="flex-space-end">更多>></div>
          </div>
        </div>


        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>产品三维</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">3D Model Production</h3>
        </div>
        <!-- 全景拍摄列表 -->
        <div class="" style="display: flex;justify-content: center;margin-bottom: 45px;">
          <div class="Showroom-box">
            <div class="Showroom" v-for="(item,index) in modelList" :key="index" @click="goPage(item.goUrl)">
              <div class="Showroom-img">
                <img :src="item.image">
              </div>
              <div class="Showroom-title">{{item.title}}</div>
            </div>
            <!-- 更多 -->
            <div class="flex-space-end">更多>></div>
          </div>
        </div>


        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>全景视频</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Panoramic video</h3>
        </div>
        <!-- 全景拍摄列表 -->
        <div class="" style="display: flex;justify-content: center;margin-bottom: 45px;">
          <div class="Showroom-box">
            <div class="Showroom" v-for="(item,index) in videoList" :key="index" @click="goPage(item.goUrl)">
              <div class="Showroom-img">
                <img :src="item.image">
              </div>
              <div class="Showroom-title">{{item.title}}</div>
            </div>
            <!-- 更多 -->
            <div class="flex-space-end">更多>></div>
          </div>
        </div>


      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
  import footers from '@/components/pc/footers.vue'
  import headers from '@/components/pc/headers.vue'
  import {
    selectNavBarAndBanner
  } from '@/api/home'
  import {
    select
  } from '../../api/news.js'
  import {
    tdk
  } from '../../api/tdk'

  export default {
    components: {
      headers,
      footers
    },
    data() {
      return {
        //虚拟展厅列表
        ShowroomList: [
			{
			    image: "https://hs-1253640467.cos.ap-guangzhou.myqcloud.com/xnfile/1642416823674%E5%9B%BE%E7%89%871.png",
			    title: '国家宪法日主题宣传云展厅',
			    goUrl: 'https://vrshow.yskj3d.com/scenes/yunshi01/index.html'
			  },
			  {
			      image: "https://hs-1253640467.cos.ap-guangzhou.myqcloud.com/xnfile/1642417093652%E5%9B%BE%E7%89%872.png",
			      title: '港珠澳大桥出入境边防检查站网上警史馆',
			      goUrl: 'https://vrshow.yskj3d.com/scenes/yunshi02/index.html'
			    },
				{
				    image: "https://hs-1253640467.cos.ap-guangzhou.myqcloud.com/xnfile/1642417205271%E5%9B%BE%E7%89%873.png",
				    title: '反洗钱宣传云展厅',
				    goUrl: 'https://vrshow.yskj3d.com/scenes/yunshi04/index.html'
				  },
		    {
		        image: "https://hs-1253640467.cos.ap-guangzhou.myqcloud.com/xnfile/1642417318212%E5%9B%BE%E7%89%874.png",
		        title: '亿方资本工园VR数字展厅',
		        goUrl: 'https://vrshow.yskj3d.com/scenes/yunshi05/index.html'
		      },
			{
			    image: "https://hs-1253640467.cos.ap-guangzhou.myqcloud.com/xnfile/1642417457837%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220117190311.png",
			    title: '罗森展厅',
			    goUrl: 'http://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1642416908538%E7%BD%97%E6%A3%AE%E5%B1%95%E5%8E%85%E6%BC%94%E7%A4%BA%E8%A7%86%E9%A2%91.mp4'
			  },
			{
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632564927323u41.svg",
            title: '反洗钱VR展厅',
            goUrl: 'https://manager.yskj3d.com/jsp/FanXiQianZhiHuiZhanTing/ab39f52920e66709e51268ef3cef31d8/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632565051264u39.svg",
            title: 'CHINAROCK科技研学会',
            goUrl: 'https://manager.yskj3d.com/jsp/chinarock/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632565083355u40.svg",
            title: 'Wakabeauty 保健品展厅',
            goUrl: 'https://manager.yskj3d.com/jsp/wakebeautyChanPinZhan/scenes/ac9b96c0be3108dae82636287cb16b00/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1633934143450%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20211011143518.png",
            title: '中国联通周年庆',
            goUrl: 'https://manager.yskj3d.com/jsp/ZhongGuoLianTong5GZhanTing/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632565578592u42.svg",
            title: '海创汇VR展厅',
            goUrl: 'https://manager.yskj3d.com/jsp/HaiChuangHuiVRZhanTing/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632565594361u43.svg",
            title: '商斯迈科技展厅',
            goUrl: 'https://manager.yskj3d.com/jsp/ShangShiMaiVRZhanTing/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1633934078863%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20211011142944.png",
            title: '江苏省自动化协会VR科普厅',
            goUrl: 'https://manager.yskj3d.com/jsp/JiangSuShengZiDongHuaXueHuiVRKePuZhanTing/index.html'
          }
        ],

        //全景拍摄列表
        ShootingList: [{
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567101445u46.svg",
            title: '创维VR',
            goUrl: 'https://manager.yskj3d.com/jsp/ShenZhengChuangWei/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567110014u47.svg",
            title: '大鹏半岛',
            goUrl: 'https://720yun.com/t/dac27qsvwun?scene_id=1099821&from=singlemessage&isappinstalled=0#scene_id=1107400'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567117910u48.svg",
            title: '富力·悦喜城',
            goUrl: 'https://www.zplus.cn/wwz/1943fuliyuexicheng/index.php?share_id=0&show_user_status=1&referrer=1&Edition=02f4b6c2b9b79a9b6868ced06242d888#'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567125523u49.svg",
            title: '广东师范科技大学',
            goUrl: 'https://manager.yskj3d.com/jsp/GuangDongJiShuShiFanDaXue/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567133637u50.svg",
            title: '利勃海尔',
            goUrl: 'https://manager.yskj3d.com/jsp/Liebherr/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567140428u51.svg",
            title: '庆元香菇博物馆',
            goUrl: 'https://manager.yskj3d.com/jsp/ZhongGuoQingYuanXiangGuBoWuGuan/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567147151u52.svg",
            title: '深圳图书馆',
            goUrl: 'https://manager.yskj3d.com/jsp/RongGuZhuJin/index.html'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567153965u53.svg",
            title: '香港中文大学',
            goUrl: 'https://manager.yskj3d.com/jsp/VRManYouXiangGangZhongWenDaXue/index.html'
          }
        ],

        //产品三维列表
        modelList: [{
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567371898u54.svg",
            title: '文物-鼎',
            goUrl: 'https://www.ddd.online/jq/webEdit/project/embedProject/mE69j61q-w94shrl9-Yx9FabCF-FoaCjJJx?hideHead=1&hideLogo=1&hideShare=1&hideDownload=1'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567379908u55.svg",
            title: '长安汽车',
            goUrl: 'https://www.changan.com.cn/3Dhall/UNI-T/'
          }
        ],


        //全景视频列表
        videoList: [{
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567526948u57.svg",
            title: '俯瞰深大',
            goUrl: 'http://cache.utovr.com/player_share_new.html?vcode=102106827618'
          },
          {
            image: "https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1632567517895u56.svg",
            title: '鞍钢集团',
            goUrl: 'http://cache.utovr.com/player_share_new.html?vcode=121308068460'
          }
        ],

      }
    },
    mounted() {

    },
    methods: {
      goPage(goUrl){
        window.open(goUrl, '_blank');
      }
    }
  }
</script>

<style lang="less" scoped>
  .caseCenter {
    width: 100%;
    height: 100%;
    background: #f0f6ff;

    .layer1 {
      width: 100%;
      // height: 350px;
      background: #f0f6ff;
      margin-bottom: 40px;

      .bg,
      .tv {
        width: 100%;
        height: 100%;

        iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }

        .bgImg {
          display: block;
          width: 100%;
          height: auto;
          // object-fit: cover;
        }
      }
    }

    .block {
      width: 400px;
      padding-bottom: 50px;
      margin: 0 auto;

      /deep/ .el-pagination {
        .el-pager {
          li {
            background-color: transparent;
          }
        }

        .btn-next,
        .btn-prev {
          background-color: transparent;
        }
      }
    }
  }

  .layer2 {
    .content {
      width: 1400px;
      margin: 0 auto;

      .nav {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;

        li {
          width: 138px;
          list-style: none;

          img {
            display: block;
          }

          span {
            font-size: 22px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #4486ff;
            line-height: 37px;
          }

          text-align: center;
        }
      }

      .title {
        width: 1130px;
        margin: 0 auto;
        margin-bottom: 45px;

        .title_main {
          display: flex;

          img {
            width: 416px;
            height: 20px;
            margin-top: 40px;
          }

          h2 {
            font-size: 51px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            margin-left: 20px;
            margin-right: 20px;
          }
        }

        .subtitle {
          font-size: 22px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #4c8dfc;
          text-align: center;
          letter-spacing: 8px;
          margin-top: 5px;
        }

        .subtitle2 {
          font-size: 20px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #6c7ba8;
          text-align: center;
          margin-top: 10px;
        }
      }

      .choose {
        width: 868px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;

        li {
          text-align: center;
          list-style: none;
          width: 120px;
          height: 42px;
          background: linear-gradient(0deg, #f7faff, #e0ebff, #f9fbff);
          border: 1px solid #dbe7ff;
          box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
          border-radius: 21px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #6c7ba8;
          line-height: 41px;
          cursor: pointer;
        }

        .active {
          background: linear-gradient(0deg, #abd4ff, #76acff, #4783f3);
          border: 1px solid #b9d8ff;
          box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
          border-radius: 21px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #ffffff;
          line-height: 41px;
        }
      }

      .videoBox {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 90px;
        border-radius: 20px;
        overflow: hidden;

        #video1 {
          width: 100%;
        }
      }

      .application {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 43px;

        &::after {
          content: '';
          width: 450px;
        }

        li {
          width: 450px;
          height: 280px;
          margin-bottom: 26px;
          border-radius: 20px;
          overflow: hidden;

          video {
            width: 100%;
            height: 100%;
            background-color: gray;
            object-fit: fill;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .Showroom-box {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
  }

  .Showroom-img img {
    width: 350px;
    height: 200px;
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
  }

  .Showroom-title {
    font-size: 1rem;
    color: #111111;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
  }

  .Showroom {
    margin-bottom: 30px;
    margin-right: 75px;
  }

  .Showroom:nth-child(3n) {
    margin-right: 0;
  }

  .flex-space-end {
    width: 1200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #1C3CFF;
    font-size: 1.125rem;
    cursor: pointer;
  }
</style>
