import _fetch from '@/api/request.js'

//查询虚拟展厅介绍部分
function selectAR (data) {
  return _fetch({
    url: '/api/v1/ar',
    method: 'post',
    data
  })
}

export {selectAR}
