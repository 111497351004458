import Vue from 'vue'
import VueRouter from 'vue-router'
// pc
import HomePC from '@/views/pc/HomePC.vue'
import VirtualGalleryPC from '@/views/pc/VirtualGalleryPC.vue'
import CloudConventionPC from '@/views/pc/CloudConventionPC.vue'
import VR_PC from '@/views/pc/VR_PC.vue'
import AR_PC from '@/views/pc/AR_PC.vue'
import VideoProductionPC from '@/views/pc/VideoProductionPC.vue'
import NewsCenter from '@/views/pc/NewsCenter.vue'
import About_US from '@/views/pc/About_US.vue'
import NewsDetails from '@/views/pc/NewsDetails.vue'
import caseCenter from '@/views/pc/caseCenter.vue'
// pe
import Home_PE from '@/views/pe/Home_PE.vue'
import VirtualGallery_PE from '@/views/pe/VirtualGallery_PE.vue'
import CloudHall_PE from '@/views/pe/CloudHall_PE.vue'
import AR_Application_PE from '@/views/pe/AR_Application_PE.vue'
import VR_Panoramic_PE from '@/views/pe/VR_Panoramic_PE.vue'
import VideoProduction_PE from '@/views/pe/VideoProduction_PE.vue'
import NewsCenter_PE from '@/views/pe/NewsCenter_PE.vue'
import AboutUS_PE from '@/views/pe/AboutUS_PE.vue'
import NewsDetails_PE from '@/views/pe/NewsDetails_PE.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: () => import('../views/pc/MainPage.vue'),
    redirect: '/',
    children: [
      {
        path: '/',
        name: 'HomePC',
        component: HomePC
      },
      {
        path: '/HomePC',
        name: 'HomePC',
        component: HomePC
      },
      {
        path: '/VirtualGalleryPC',
        name: 'VirtualGalleryPC',
        component: VirtualGalleryPC
      },
      {
        path: '/CloudConventionPC',
        name: 'CloudConventionPC',
        component: CloudConventionPC
      },
      {
        path: '/VR_PC',
        name: 'VR_PC',
        component: VR_PC
      },
      {
        path: '/AR_PC',
        name: 'AR_PC',
        component: AR_PC
      },
      {
        path: '/VideoProductionPC',
        name: 'VideoProductionPC',
        component: VideoProductionPC
      },
      {
        path: '/NewsCenter',
        name: 'NewsCenter',
        component: NewsCenter
      },
      {
        path: '/About_US',
        name: 'About_US',
        component: About_US
      },
      {
        path: '/NewsDetails',
        name: 'NewsDetails',
        component: NewsDetails
      },
      {
        path: '/caseCenter',
        name: 'caseCenter',
        component: caseCenter
      }
    ]
  },
  {
    path: '/m',
    name: 'mMainPage',
    component: () => import('../views/pe/MainPage.vue'),
    redirect: '/home_pe',
    children: [
      {
        path: '/home_pe',
        name: 'Home_PE',
        component: Home_PE
      },
      {
        path: '/virtualGallery_pe',
        name: 'VirtualGallery_PE',
        component: VirtualGallery_PE
      },
      {
        path: '/cloudhall_pe',
        name: 'CloudHall_PE',
        component: CloudHall_PE
      },
      {
        path: '/ar_application_pe',
        name: 'AR_Application_PE',
        component: AR_Application_PE
      },
      {
        path: '/vr_panoramic_pe',
        name: 'VR_Panoramic_PE',
        component: VR_Panoramic_PE
      },
      {
        path: '/videoproduction_pe',
        name: 'VideoProduction_PE',
        component: VideoProduction_PE
      },
      {
        path: '/newscenter_pe',
        name: 'NewsCenter_PE',
        component: NewsCenter_PE
      },
      {
        path: '/aboutus_pe',
        name: 'AboutUS_PE',
        component: AboutUS_PE
      },
      {
        path: '/NewsDetails_PE',
        name: 'NewsDetails_PE',
        component: NewsDetails_PE
      }
    ]
  },
  {
    path: '/HomePC',
    name: 'HomePC',
    component: HomePC
  },
  {
    path: '/VirtualGalleryPC',
    name: 'VirtualGalleryPC',
    component: VirtualGalleryPC
  },
  {
    path: '/CloudConventionPC',
    name: 'CloudConventionPC',
    component: CloudConventionPC
  },
  {
    path: '/VR_PC',
    name: 'VR_PC',
    component: VR_PC
  },
  {
    path: '/AR_PC',
    name: 'AR_PC',
    component: AR_PC
  },
  {
    path: '/VideoProductionPC',
    name: 'VideoProductionPC',
    component: VideoProductionPC
  },
  {
    path: '/NewsCenter',
    name: 'NewsCenter',
    component: NewsCenter
  },
  {
    path: '/About_US',
    name: 'About_US',
    component: About_US
  },
  {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: NewsDetails
  },
  {
    path: '/caseCenter',
    name: 'caseCenter',
    component: caseCenter
  },
  {
    path: '/home_pe',
    name: 'Home_PE',
    component: Home_PE
  },
  {
    path: '/virtualGallery_pe',
    name: 'VirtualGallery_PE',
    component: VirtualGallery_PE
  },
  {
    path: '/cloudhall_pe',
    name: 'CloudHall_PE',
    component: CloudHall_PE
  },
  {
    path: '/ar_application_pe',
    name: 'AR_Application_PE',
    component: AR_Application_PE
  },
  {
    path: '/vr_panoramic_pe',
    name: 'VR_Panoramic_PE',
    component: VR_Panoramic_PE
  },
  {
    path: '/videoproduction_pe',
    name: 'VideoProduction_PE',
    component: VideoProduction_PE
  },
  {
    path: '/newscenter_pe',
    name: 'NewsCenter_PE',
    component: NewsCenter_PE
  },
  {
    path: '/aboutus_pe',
    name: 'AboutUS_PE',
    component: AboutUS_PE
  },
  {
    path: '/NewsDetails_PE',
    name: 'NewsDetails_PE',
    component: NewsDetails_PE
  }
]

const router = new VueRouter({
  mode: 'history', // 模式，默认hash
  routes
})

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next()
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
