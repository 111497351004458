<template>
  <div class="navbar">
    <i class="el-icon-s-unfold" @click="show = true"></i>
    <img class="logo" src="@/assets/pelogo.png" alt="" />
    <!-- 菜单列表 -->
    <van-popup
      position="left"
      :style="{ width: '35%', height: '100%' }"
      v-model="show"
      class="menuFz"
    >
      <ul class="menu">
        <li
          v-for="(item, index) in menuArr"
          :key="index"
          @click="toWhere(index)"
          :class="$store.state.currentIndex == index ? 'active' : ''"
        >
          {{ item }}
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      menuArr: [
        '首页',
        '虚拟展厅',
        '云会展',
        'AR解决方案',
        'VR全景',
        '视频制作',
        '新闻中心',
        '关于我们'
      ],
      currentIndex: 0
    }
  },
  methods: {
    //打开关闭菜单
    openMenu () {
      this.$store.commit('setDrawer', true)
    },
    toWhere (index) {
      switch (index) {
        case 0:
          //首页
          this.$router.push('/home_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',0)
          break
        case 1:
          //虚拟展厅
          this.$router.push('/virtualGallery_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',1)

          break
        case 2:
          //云会展
          this.$router.push('/cloudhall_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',2)

          break
        case 3:
          //AR应用
          this.$router.push('/ar_application_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',3)

          break
        case 4:
          //VR全景
          this.$router.push('/vr_panoramic_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',4)

          break
        case 5:
          //视频制作
          this.$router.push('/videoproduction_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',5)

          break
        case 6:
          //新闻中心
          this.$router.push('/newscenter_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',6)

          break
        case 7:
          //关于我们
          this.$router.push('/aboutus_pe')
          this.$store.commit('setMao', 0)
          this.$store.commit('setCurrentIndex',7)

          break

        default:
          console.log('不存在此页面')
          break
      }
    }
  }
}
</script>

<style lang="less">
.navbar {
  width: 100%;
  height: 40px;
  position: sticky;
  top: 0;
  z-index: 24000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  .el-icon-s-unfold {
    font-size: 30px;
    margin-left: 5px;
  }
  .logo {
    width: 82px;
    height: auto;
    position: absolute;
    left: 50%;
    margin-left: -41px;
  }
  .menuFz {
    z-index: 10000 !important;
  }
  .menu {
    li {
      text-indent: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 0.5px solid #ccc;
      font-weight: 400;
      font-size: 21;
    }
  }
  .active {
    color: #034eb7;
    border-right: 3px solid #034eb7;
  }
}
</style>
