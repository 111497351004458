import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/base.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import $ from 'jquery'
import Vant from 'vant'
import 'vant/lib/index.css'
import MetaInfo from 'vue-meta-info';
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(MetaInfo)


new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
