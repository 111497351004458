<template>
  <div class="NewsCenter_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        src="https://720yun.com/t/1evkt7dqp1l?scene_id=73707514"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <div class="layer2">
      <ul class="nav">
        <li
          @click="toNewsDetails_PE(item.id)"
          v-for="(item, index) in List"
          :key="index"
        >
          <div class="room">
            <img :src="'http://' + item.img_address.substring(13)" alt="" />
          </div>
          <p>{{ item.title }}</p>
        </li>
      </ul>
      <div class="block" style="padding:4px 0 4px 28px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="page_count"
        >
        </el-pagination>
      </div>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { select } from '../../api/news.js'
import { tdk } from '../../api/tdk'

export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      type: '',
      img: '',
      tv: '',
      List: '',
      // 当前第几页
      currentPage: 1,
      // 总共多少条数据
      total: 0,
      // 页容量
      pageSize: 12,
      page_count: 0,
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init1()
    this.init2()
    this.getTdk()
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '新闻中心'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[6].type
      this.img = 'http://' + res.data.data.data[6].img_address.substring(13)
      this.tv = res.data.data.data[6].url_address
      console.log('nowType', this.type)
      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await select({
        page: this.currentPage,
        page_size: this.pageSize
      })
      console.log('init2', res)
      this.List = res.data.data.data.reverse()
      this.total = res.data.data.total_pages
      this.page_count = res.data.data.page_count
    },
    toNewsDetails_PE (id) {
      window.localStorage.setItem('current_id', id)
      this.$router.push('/NewsDetails_PE')
    }
  }
}
</script>

<style lang="less" scoped>
.NewsCenter_PE {
  width: 100%;
  height: 100%;
  position: relative;
  .layer1_tv {
    width: 100%;
    height: 210px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .layer1 {
    width: 100%;
    height: 210px;
    background: url('../../assets/PE_IMG/新闻中心/u321.png');
    background-size: 100% 100%;
  }
  .layer2 {
    width: 100%;

    padding-bottom: 50px;
    .nav {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        width: 150px;
        margin-bottom: 15px;
        .room {
          width: 150px;
          height: 90px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          text-align: center;
          font-size: 13px;
          color: #666;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
