<template>
  <div class="AboutUS_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        :src="tv"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <div class="layer2">
      <div class="title">
        公司简介
      </div>
      <div class="content">
        深圳市云视空间科技有限公司致力于提供企业的数字化转型整体解决方案。主营虚拟展厅设计、VR全景拍摄、3D数字建模、AR/VR场景应用、视频制作、系统定制化开发等业务。我们的愿景是为全国每一个企业提供定制化数字解决方案，助力企业开启数字化营销新模式。总公司位于广东省深圳市，分公司位于江西省会南昌，并在广州、上海等地设有办事处。凭借着丰富的经验和过硬的制作团队及营销策划团队，为客户提供了一系列高效，高性价比的企业数字化营销解决方案。经过我司技术人员不断努力，发布平台全方位覆盖互联网各主要终端，如手机、平板电脑、PC电脑等，通过QQ、微信、网站等多渠道推广方式，为客户提供多元化的展示方案。
      </div>
      <div class="title">
        联系我们
      </div>
      <div id="map"></div>
      <div class="nav">
        <li v-for="(item, index) in list" :key="index">
          <img :src="'http://' + item.img_address.substring(13)" alt="" />
          <span>{{ item.title }} : {{ item.desc }}</span>
        </li>
      </div>
    </div>
    <footerPe></footerPe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerPe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { select2 } from '../../api/aboutus.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerPe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      type: '',
      img: '',
      tv: '',
      list: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.getTdk()
    this.init1()
    this.init2()
    // 地图
    var map = new AMap.Map('map', {
      zoom: 17.2, //级别
      center: [114.252392, 22.713786], //中心点坐标
      viewMode: '3D' //使用3D视图
    })
    var marker = new AMap.Marker({
      position: new AMap.LngLat(114.252392, 22.713786), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: '北京'
    })
    map.add(marker)

    //鼠标点击marker弹出自定义的信息窗体
    AMap.event.addListener(marker, 'click', function () {
      infoWindow.open(map, marker.getPosition())
    })

    marker.setTitle('深圳市龙岗区龙城街道花样年龙年广场2401')
    // 同时引入工具条插件，比例尺插件和鹰眼插件
    AMap.plugin(
      [
        'AMap.ToolBar',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.MapType',
        'AMap.Geolocation'
      ],
      function () {
        // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
        map.addControl(new AMap.ToolBar())

        // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
        map.addControl(new AMap.Scale())
      }
    )

    // message
    // 信息窗体的内容
    var content = [
      '<div><img src=""" http:="" webapi.amap.com="" images="" autonavi.png="" "=""> ',
      '<div style=""padding:0px" 0px="" 4px;"=""><b>深圳市云视空间科技有限公司</b>',
      '地址 : 深圳市龙岗区龙城街道花样年龙年广场2401</div></div>'
    ]

    // 创建 infoWindow 实例
    var infoWindow = new AMap.InfoWindow({
      content: content.join('<br>') //传入 dom 对象，或者 html 字符串
    })

    // 打开信息窗体
    infoWindow.open(map)
    console.log('mao', this.$store.state.mao)
    if (this.$store.state.mao == 1) {
      //锚点
      let anchor = document.getElementsByClassName('layer4')[0]
      if (!!anchor) {
        anchor.scrollIntoView(true)
      }
    }
    if (this.$store.state.mao == 2) {
      //锚点
      let anchor = document.getElementsByClassName('layer2')[0]
      if (!!anchor) {
        anchor.scrollIntoView(true)
      }
    }
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '关于我们'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[7].type
      this.img = 'http://' + res.data.data.data[7].img_address.substring(13)
      this.tv = res.data.data.data[7].url_address
      console.log('nowType', this.type)
      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await select2({})
      console.log('init3', res)
      this.list = res.data.data.data
    }
  }
}
</script>

<style lang="less" scoped>
.AboutUS_PE {
  width: 100%;
  .layer1 {
    width: 100%;
    height: 210px;
    background: url('../..//assets/PE_IMG/关于我们/u472.jpg');
    background-size: 100% 100%;
  }
  .layer2 {
    width: 100%;
    .title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-indent: 20px;
      font-size: 13px;
      font-weight: 700;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
    }
    .content {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 12px;
      line-height: 24px;
      margin-top: 10px;
    }
    #map {
      width: 314px;
      height: 160px;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      background-color: pink;
    }
    .nav {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        img {
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }
        span {
          font-size: 13px;
        }
        .s {
          width: 170px;
        }
      }
    }
  }
}
</style>
