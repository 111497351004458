<template>
  <div class="About_US">
    <headers></headers>
    <div class="layer1">
      <div class="bg" v-show="layer1Type == 2">
        <img class="bgImg" :src="bg" alt="" />
      </div>
      <div class="tv" v-show="layer1Type == 1">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="tv"
        ></iframe>
      </div>
    </div>
    <div class="layer2">
      <div class="content1">
        <div class="left">
          <div class="title">
            <div class="decoration"></div>
            <h3>公司介绍</h3>
          </div>
          <p class="introduce">
            {{ introduce.desc }}
          </p>
        </div>
        <img
          src="../../assets/img/pc/About_US/5922a94b1edbf@2x.png"
          alt=""
          class="right"
        />
      </div>
      <div class="content2">
        <div class="left">
          <div id="map"></div>
        </div>
        <div class="right">
          <div class="title">
            <div class="decoration"></div>
            <h3>联系我们</h3>
          </div>
          <ul class="introduce">
            <li v-for="(item, index) in contactList" :key="index">
              <img
                :src="'http://' + item.img_address.substring(13)"
                alt=""
              /><span>{{ item.desc }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { select1, select2 } from '../../api/aboutus'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      contactList: '',
      introduce: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init()
    // 初始化地图
    this.mapInit()
    this.init2()
    this.getTdk()
  },
  methods: {
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[7].type
      setTimeout(() => {
        switch (res.data.data.data[7].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[7].img_address.substring(13)
            break
          case 1:
            this.tv = res.data.data.data[7].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      // 公司介绍
      let res = await select1({})
      console.log('公司介绍', res)
      this.introduce = res.data.data.data[0]
      // 联系方式
      let res2 = await select2({})
      console.log('联系方式', res2)
      this.contactList = res2.data.data.data
    },
    mapInit () {
      // 地图
      var map = new AMap.Map('map', {
        zoom: 17.2, //级别
        center: [114.252392, 22.713786], //中心点坐标
        viewMode: '3D' //使用3D视图
      })
      var marker = new AMap.Marker({
        position: new AMap.LngLat(114.252392, 22.713786), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '北京'
      })
      map.add(marker)

      //鼠标点击marker弹出自定义的信息窗体
      AMap.event.addListener(marker, 'click', function () {
        infoWindow.open(map, marker.getPosition())
      })

      marker.setTitle('深圳市龙岗区龙城街道花样年龙年广场2401')
      // 同时引入工具条插件，比例尺插件和鹰眼插件
      AMap.plugin(
        [
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.OverView',
          'AMap.MapType',
          'AMap.Geolocation'
        ],
        function () {
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          map.addControl(new AMap.ToolBar())

          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          map.addControl(new AMap.Scale())

          // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
          map.addControl(new AMap.MapType())
        }
      )

      // message
      // 信息窗体的内容
      var content = [
        '<div><img src=""" http:="" webapi.amap.com="" images="" autonavi.png="" "=""> ',
        '<div style=""padding:0px" 0px="" 4px;"=""><b>深圳市云视空间科技有限公司</b>',
        '地址 : 深圳市龙岗区龙城街道花样年龙年广场2401</div></div>'
      ]

      // 创建 infoWindow 实例
      var infoWindow = new AMap.InfoWindow({
        content: content.join('<br>') //传入 dom 对象，或者 html 字符串
      })
    },
    async getTdk () {
      let res = await tdk({
        name: '关于我们'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    }
  }
}
</script>

<style lang="less" scoped>
.About_US {
  width: 100%;
  height: 100%;
  background: #f0f6ff;
  .layer1 {
    width: 100%;
    height: 372px;
    background: #f0f6ff;
    margin-bottom: 60px;
    .bg,
    .tv {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .layer2 {
    padding-bottom: 134px;
    .content1 {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 0 100px;
      margin-bottom: 90px;
      .left {
        width: 550px;
        height: 363px;
        .title {
          display: flex;
          align-items: center;
          margin-bottom: 50px;
          margin-top: 30px;
          .decoration {
            width: 7px;
            height: 28px;
            background: #4c8dfc;
            margin-right: 11px;
          }
          h3 {
            font-size: 30px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #6c7ba8;
          }
        }
        .introduce {
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #6d7382;
          line-height: 32px;
          width: 522px;
        }
      }
      .right {
        width: 550px;
        height: 363px;
      }
    }
    .content2 {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 0 100px;
      .left {
        width: 550px;
        height: 363px;
        #map {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 550px;
        height: 363px;
        .title {
          display: flex;
          align-items: center;
          margin-bottom: 50px;
          margin-top: 30px;
          .decoration {
            width: 7px;
            height: 28px;
            background: #4c8dfc;
            margin-right: 11px;
          }
          h3 {
            font-size: 30px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #6c7ba8;
          }
        }
        .introduce {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            img {
              margin-right: 18px;
            }
            span {
              font-size: 18px;
              font-family: Alibaba PuHuiTi;
              font-weight: 400;
              color: #6d7382;
            }
          }
        }
      }
    }
  }
}
</style>
