<template>
  <div class="HomePC">
    <headers></headers>
    <div class="contents">
      <!-- <div class="layer1">
        <div class="layer1_BtnBox">
          <div class="toVR">
            <span @click="openDialog">进入VR云官方</span
            ><img src="../../assets/img/pc/Home/进入.png" alt="" />
          </div>
          <div class="toContactUS" @click="toAbout_US('/About_US')">
            <img src="../../assets/img/pc/Home/联系.png" alt="" /><span
              >联系我们</span
            >
          </div>
        </div>
        <div class="bg" v-show="layer1Type == 2">
          <img class="bgImg" :src="bg" alt="" />
        </div>
        <div class="tv" v-show="layer1Type == 1">
          <iframe
            id="u254_input"
            scrolling="auto"
            frameborder="1"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            :src="tv"
          ></iframe>
        </div>
      </div> -->
	  
	  <!-- 顶部图 -->
	  <div class="">
	  	<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348068660351.jpg" style="width: 100%;height: 600px;cursor: pointer;margin-bottom: 50px;" @click="goNewPage()">
	  </div>
	  
      <div class="layer2">
        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>核心业务</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Core business</h3>
        </div>
        <ul class="core_advantage">
          <li v-for="(item, index) in coreBusinessInfo" :key="index">
            <img :src="'http://' + item.img_address.substring(13)" alt="" @click="toCoreBusiness(index)"/>
            <p class="introduce">
              {{ item.desc }}
            </p>
            <div class="more" @click="toCoreBusiness(index)">
              <span>more</span><i class="el-icon-arrow-right"></i>
            </div>
          </li>
        </ul>
        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>行业应用</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Industry Application</h3>
        </div>
        <div class="industryApplication">
          <ul class="choose">
            <li
              v-for="(item, index) in choose"
              :key="index"
              :class="currentIndex == index ? 'actives' : ''"
              @click="currentIndex = index"
            >
              {{ item }}
            </li>
          </ul>
          <div class="content">
            <div class="figure">
              <img :src="chooseInfo[currentIndex].img" alt="" />
            </div>
            <div class="introduce">
              <div class="title">
                <span class="decoration"></span>
                <h2>{{ chooseInfo[currentIndex].info.title }}</h2>
              </div>
              <div class="instructions">
                {{ chooseInfo[currentIndex].info.introduce }}
              </div>
              <ul class="nav">
                <li
                  v-for="(item, index) in chooseInfo[currentIndex].info.list"
                  :key="index"
                >
                  <img :src="item.icon" alt="" />
                  <p>{{ item.text }}</p>
                </li>
              </ul>
              <div class="more" @click="currentIndexTo(currentIndex)">
                <span>more</span><i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="company_introduction">
          <div class="left">
            <div class="titles">
              <span class="decoration"></span>
              <h2>云视空间</h2>
            </div>
            <div class="introduce">
              深圳市云视空间科技有限公司致力于提供企业的数字化转型整体解决方案。主营虚拟展厅设计、VR全景拍摄、3D数字建模、AR/VR场景应用、视频制作等业务。我们的愿景是为全国每一个企业提供定制化数字解决方案，助力企业开启数字化营销新模式。
            </div>
            <div class="more" @click="toAbout_US('/About_US')">
              <span>more</span><i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="right"></div>
        </div>
		
		<div class="title">
		  <div class="title_main">
		    <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
		    <h2>荣耀资质</h2>
		    <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
		  </div>
		  <h3 class="subtitle">HONOR</h3>
		</div>
		
		<!-- 证书动画 -->
		<div class="container-fixed stacked-cards stacked-cards-slide">
					<ul>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348068985282.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069141913.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069263104.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069390755.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069513416.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069655937.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069811848.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/16348069866819.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/163480700580210.jpg" >
						</li>
						<li>
							<img src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/163480701163911.jpg" >
						</li>
					</ul>
				</div>
		
        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>合作伙伴</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">cooperative partner</h3>
        </div>
        <div class="partners">
          <li v-for="(item, index) in partners" :key="index">
            <img :src="'http://' + item.img_address.substring(13)" alt="" />
          </li>
        </div>
      </div>
    </div>
    <footers :message="msg"></footers>
    <el-dialog
      class="dialogs"
      title=" "
      :visible.sync="dialogVisible"
      width="1500px"
    >
      <div class="dialogsContent">
        <div class="tv">
          <iframe
            id="u254_input"
            scrolling="auto"
            frameborder="1"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            :src="tv2"
          ></iframe>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
	import $ from 'jquery'
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import {stackedCards} from '../../api/stackedCards.min.js'
import {
  selectNavBarAndBanner,
  selectPartners,
  selectCoreBusiness
} from '@/api/home'
import { getLocal, setLocal } from '../../utils/local.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      dialogVisible: false,
      layer1Type: 0,
      currentIndex: 0,
      choose: ['虚拟展厅', 'AR', 'VR', '视频制作'],
      chooseInfo: [
        {
          img: require('../../assets/img/pc/Home/12.png'),
          info: {
            title: '虚拟展厅',
            introduce:
              '虚拟展厅能有效的展示您的品牌或产品。无论是展示厅、博览会、展位，还是整个世界，我们为您开发数字时代的虚拟空间，以视频、直播、图片和文件形式提供更多的信息。',
            list: [
              {
                icon: require('../../assets/img/pc/Home/图层 31@2x.png'),
                text: 'VR云官网'
              },
              {
                icon: require('../../assets/img/pc/Home/科技馆@2x.png'),
                text: '企业科技馆'
              },
              {
                icon: require('../../assets/img/pc/Home/党建@2x.png'),
                text: '党建馆'
              },
              {
                icon: require('../../assets/img/pc/Home/博物馆@2x.png'),
                text: '博物馆'
              },
              {
                icon: require('../../assets/img/pc/Home/学校@2x.png'),
                text: '书画馆'
              },
              {
                icon: require('../../assets/img/pc/Home/书画室@2x.png'),
                text: '校史馆'
              }
            ]
          }
        },
        {
          img: require('../../assets/img/pc/Home/10.png'),
          info: {
            title: 'AR应用',
            introduce:
              '增强现实(AR)技术与行业结合可以提升行业价值、带来新的解决方案并提升用户体验。很多企业希望将AR技术纳入到自己的生产与销售过程中，所以投资或收购AR相关企业，这有效地推动了增强现实行业的增长。',
            list: [
              {
                icon: require('../../assets/img/pc/Home/营销@2x.png'),
                text: 'AR营销'
              },
              {
                icon: require('../../assets/img/pc/Home/名片@2x.png'),
                text: 'AR名片'
              },
              {
                icon: require('../../assets/img/pc/Home/房产@2x.png'),
                text: 'AR房产'
              },
              {
                icon: require('../../assets/img/pc/Home/教育@2x.png'),
                text: 'AR教育'
              },
              {
                icon: require('../../assets/img/pc/Home/工业@2x.png'),
                text: 'AR工业'
              },
              {
                icon: require('../../assets/img/pc/Home/交互@2x.png'),
                text: 'AR产品交互'
              }
            ]
          }
        },
        {
          img: require('../../assets/img/pc/Home/11.png'),
          info: {
            title: 'VR应用',
            introduce:
              '戴上VR，新疆喀纳斯湖的美景实时呈现在眼前；通过VR修复技术，人们可以观赏景德镇御窑昔日盛景；进入微信小程序，足不出户就能全景观看楼盘样板房……虚拟现实技术解锁的多种应用新场景正变成现实。',
            list: [
              {
                icon: require('../../assets/img/pc/Home/智慧文旅@2x.png'),
                text: 'VR文旅'
              },
              {
                icon: require('../../assets/img/pc/Home/校园 (4)@2x.png'),
                text: 'VR校园'
              },
              {
                icon: require('../../assets/img/pc/Home/展厅@2x.png'),
                text: 'VR展厅'
              },
              {
                icon: require('../../assets/img/pc/Home/城  市@2x.png'),
                text: 'VR城市'
              },
              {
                icon: require('../../assets/img/pc/Home/房产 (2)@2x.png'),
                text: 'VR房产'
              },
              {
                icon: require('../../assets/img/pc/Home/博物馆@2x.png'),
                text: 'VR博物馆'
              }
            ]
          }
        },
        {
          img: require('../../assets/img/pc/Home/13.png'),
          info: {
            title: '视频制作',
            introduce:
              '我们拥有专业的技术和丰富经验的团队，可以满足您所有的视频制作求。我们为您提供全方位的视频制作服务：脚本创作、视频拍摄、后期剪辑、特效设计、旁白配音等。',
            list: [
              {
                icon: require('../../assets/img/pc/Home/电影 (1)@2x.png'),
                text: '宣传片'
              },
              {
                icon: require('../../assets/img/pc/Home/图层 6@2x.png'),
                text: 'MG动画'
              },
              {
                icon: require('../../assets/img/pc/Home/三维@2x.png'),
                text: '三维动画'
              },
              {
                icon: require('../../assets/img/pc/Home/产品@2x.png'),
                text: '产品视频'
              },
              {
                icon: require('../../assets/img/pc/Home/视频@2x.png'),
                text: '短视频'
              },
              {
                icon: require('../../assets/img/pc/Home/图层 7@2x.png'),
                text: '广告视频'
              }
            ]
          }
        }
      ],
      partners: [],
      tv: '',
      bg: '',
      coreBusinessInfo: '',
      toCoreBusinessIndex: 0,
      // tdk信息
      tdks: '',
      layer1Type2: 0,
      tv2: '',
      bg2: '',
			//域名信息
			host:'',
			host2:'',
			//传值
			msg:{
				content1:'',
				content2:''
			}
    }
  },
  created () {
    setLocal('PageIndex', 0);
		this.host = window.location.host;
		this.host2=document.domain;
		console.log(this.host)
		console.log(this.host2)
		if(this.host2=='www.yunshikongjian.com'){
			this.msg.content1='邱经理：15920033291'
			this.msg.content2='彭抿菲：18174154467'
		}else{
			this.msg.content1='彭经理：15112411921'
			this.msg.content2='卢经理：18594202046'
		}
  },
  mounted () {
    this.init()
    this.init2()
    this.init3()
    this.getTdk()
    if (this._isMobile()) {
      //移动端
      this.$router.replace('/m')
    } else {
      //PC端
      this.$router.replace('/')
    }
	
	
	//加载证书动画实例
	var stackedCard = new stackedCards({
	 	selector: '.stacked-cards',
	 	layout: "slide",
	 	transformOrigin: "bottom",
	});

	stackedCard.init();
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '首页'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    //判断是移动端还是PC端
    _isMobile () {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    },
    toCoreBusiness (index) {
      switch (index) {
        case 0:
          setLocal('PageIndex', 1)
          this.to('/VirtualGalleryPC')
          break
        case 1:
          setLocal('PageIndex', 3)
          this.to('/VR_PC')
          break
        case 2:
          setLocal('PageIndex', 5)
          this.to('/VideoProductionPC')
          break

        default:
          break
      }
    },
    // banner
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[0].type
      setTimeout(() => {
        switch (res.data.data.data[0].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[0].img_address.substring(13)
            break
          case 1:
            this.tv = res.data.data.data[0].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    // 合作伙伴
    async init2 () {
      let res = await selectPartners({})
      // console.log(res)
      this.partners = res.data.data.data
    },
    // 核心优势
    async init3 () {
      let res = await selectCoreBusiness({})
      this.coreBusinessInfo = res.data.data.data.slice(0, 3)
      console.log('核心优势', this.coreBusinessInfo)
    },
    async openDialog(){
      this.dialogVisible=true
      let res = await selectNavBarAndBanner({})
      console.log('openDialog',res);
      setTimeout(() => {
            this.tv2 = res.data.data.data[3].url_address
      }, 1000)
    },
    to (path) {
      this.$router.push(path)
    },
    toAbout_US (path) {
      setLocal('PageIndex', 7)
      this.to(path)
    },
    toVR (path) {
      setLocal('PageIndex', 3)
      this.to(path)
    },
    toVideo (path) {
      setLocal('PageIndex', 5)
      this.to(path)
    },
    currentIndexTo (currentIndex) {
      switch (currentIndex) {
        case 0:
          setLocal('PageIndex', 1)
          getLocal('PageIndex')
          this.to('/VirtualGalleryPC')
          break
        case 1:
          setLocal('PageIndex', 4)
          getLocal('PageIndex')
          this.to('/AR_PC')

          break
        case 2:
          setLocal('PageIndex', 3)
          getLocal('PageIndex')
          this.to('/VR_PC')

          break
        case 3:
          setLocal('PageIndex', 5)
          getLocal('PageIndex')
          this.to('/VideoProductionPC')
          break
        default:
          break
      }
    },
	
	//顶部图跳转链接
	goNewPage(){
			window.open('https://720yun.com/t/39vktmqe719#scene_id=75211688', '_blank');
	},
  }
}
</script>

<style lang="less" scoped>
	@import url("../../../src/assets/style/highlight.css");
	@import url("../../../src/assets/style/htmleaf-demo.css");
	@import url("../../../src/assets/style/normalize.css");
	@import url("../../../src/assets/style/stackedCards.css");
.HomePC {
  width: 100%;
  background: #f0f6ff;
  .contents {
    width: 100%;
    height: 100%;
    .layer1 {
      width: 100%;
      height: 594px;
      background: #f0f6ff;
      margin-bottom: 60px;
      position: relative;
      .layer1_BtnBox {
        width: 475px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 180px;
        left: 50%;
        margin-left: -237.5px;
        div {
          width: 207px;
          height: 58px;
          line-height: 58px;
          text-align: center;
          font-weight: 700;
          font-style: normal;
          font-size: 20px;
          color: #ffffff;
          cursor: pointer;
          text-align: center;
          display: flex;
          align-items: center;
        }
        .toVR {
          background-color: #0079fe;
          padding: 0 20px;
          img {
            margin-left: 10px;
          }
        }
        .toContactUS {
          background-color: #21d042;
          padding: 0 40px;
          img {
            margin-right: 10px;
          }
        }
      }
      .bg,
      .tv {
        width: 100%;
        height: 100%;
        iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
        .bgImg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .layer2 {
      width: 100%;
      .title {
        width: 1076px;
        margin: 0 auto;
        margin-bottom: 45px;
        .title_main {
          display: flex;
          img {
            width: 416px;
            height: 20px;
            margin-top: 40px;
          }
          h2 {
            font-size: 51px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            margin-left: 20px;
            margin-right: 20px;
          }
        }
        .subtitle {
          font-size: 22px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #4c8dfc;
          text-align: center;
          letter-spacing: 8px;
          margin-top: 5px;
        }
      }
      .core_advantage {
        width: 1400px;
        margin: 0 auto;
        margin-bottom: 86px;
        display: flex;
        justify-content: space-between;
        li {
          width: 446px;
          height: 428px;
          background: linear-gradient(180deg, #f6faff, #f1f7fe, #f2f8ff);
          box-shadow: 0px 10px 20px 0px rgba(17, 87, 183, 0.1);
          border-radius: 20px;
          list-style: none;
          position: relative;
          img {
            width: 446px;
            height: 221px;
            position: absolute;
            top: -36px;
			cursor: pointer;
          }
          .introduce {
            padding-left: 30px;
            padding-right: 30px;
            line-height: 30px;
            margin-top: 240px;
            font-size: 16px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
          }
          .more {
            width: 90px;
            height: 34px;
            background: transparent;
            border: 1px solid #7ebbff;
            border-radius: 17px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #7bb9fe;
            line-height: 28px;
            text-align: center;
            position: absolute;
            left: 24.8px;
            bottom: 37px;
          }
        }
      }
      .industryApplication {
        width: 1450px;
        height: 693px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        .choose {
          width: 540px;
          margin: 0 auto;
          margin-bottom: 70px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li {
            width: 120px;
            height: 42px;
            cursor: pointer;
            background: linear-gradient(0deg, #f7faff, #e0ebff, #f9fbff);
            border: 1px solid #dbe7ff;
            box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
            border-radius: 21px;
            list-style: none;
            font-size: 18px;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #6c7ba8;
            line-height: 42px;
            text-align: center;
          }
          .actives {
            background: linear-gradient(0deg, #abd4ff, #76acff, #4783f3);
            border: 1px solid #b9d8ff;
            box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
            border-radius: 21px;
            font-size: 18px;
            color: #ffffff;
          }
        }
        .content {
          width: 813px;
          height: 460px;
          background: linear-gradient(-1deg, #eaeef4, #ffffff);
          border: 3px solid #ffffff;
          box-shadow: 0px 17px 29px 0px rgba(9, 20, 65, 0.1);
          border-radius: 50px;
          position: absolute;
          right: 0;
          padding-top: 56px;
          padding-left: 116px;
          padding-right: 72px;
          .figure {
            width: 760px;
            height: 420px;
            position: absolute;
            left: -660px;
            top: 15px;
          }
          .title {
            display: flex;
            margin-bottom: 30px;
            margin-left: 10px;
            .decoration {
              display: block;
              width: 7px;
              height: 28px;
              background: #4c8dfc;
              margin-right: 11px;
            }
          }
          .instructions {
            font-size: 18px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #6d7382;
            line-height: 32px;
            margin-left: 10px;
          }
          .nav {
            list-style: none;
            display: flex;
            margin-top: 15px;
            li {
              margin-right: 10px;
              img {
                width: 76px;
                height: 76px;
              }
              p {
                text-align: center;
                font-size: 16px;
                font-family: Alibaba PuHuiTi;
                font-weight: 400;
                color: #6d7382;
                line-height: 41px;
              }
            }
          }
          .more {
            width: 90px;
            height: 34px;
            background: transparent;
            border: 1px solid #7ebbff;
            border-radius: 17px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #7bb9fe;
            line-height: 28px;
            text-align: center;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      .company_introduction {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 86px;
        .left {
          width: 725px;
          padding-left: 43px;
          .titles {
            display: flex;
            align-items: center;
            padding-top: 32px;
            margin-bottom: 50px;
            .decoration {
              display: block;
              width: 7px;
              height: 28px;
              background: #4c8dfc;
              margin-right: 10px;
            }
            h2 {
              font-size: 30px;
              font-family: Alibaba PuHuiTi;
              font-weight: bold;
              color: #6c7ba8;
            }
          }
          .introduce {
            width: 522px;
            font-size: 18px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #6d7382;
            margin-bottom: 56px;
          }
          .more {
            width: 90px;
            height: 34px;
            background: transparent;
            border: 1px solid #7ebbff;
            border-radius: 17px;
            font-size: 14px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #7bb9fe;
            line-height: 28px;
            text-align: center;
          }
        }
        .right {
          width: 550px;
          height: 363px;
          background: red;
          background: url('../../assets/img/pc/Home/图层 511@2x.png');
          background-size: 100% 100%;
        }
      }
      .partners {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        margin-bottom: 34px;
        li {
          width: 250px;
          height: 96px;
          list-style: none;
          margin-bottom: 13px;
          margin-right: 37px;
          &:nth-child(5n) {
            margin-right: 0px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .partners::after {
        content: '';
        width: 250px;
      }
    }
  }
  .more {
    cursor: pointer;
  }
  /deep/ .el-dialog {
    height: 800px;
    padding-bottom: 20px;
    .el-dialog__body{
      height: 100%;
      .dialogsContent{
        height: 100%;
      }
    }
    .bg,
    .tv {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        width: 100%;
        height: 100%;
      }
    }
  }
  /deep/ .dialogs {
    .dialogsContent {
    }
  }
}

//

.stacked-cards h2 {
			text-align: center;
			position: relative;
			// top: -20px;
		}

		.intro {
			max-width: 1000px;
			margin: 20px auto;
			text-align: center;
		}

		.container-fuild {
			max-width: 80%;
			margin: 0 auto;
		}

		.container-fixed {
			max-width: 1200px;
			margin: 0 auto;
		}

		.divider {
			max-width: 500px;
			margin: 25px auto;
			background-color: #ccc;
			height: 2px;
			width: 100%;
		}

		.stacked-cards {
			// padding-top: 40px;
			padding-bottom: 200px;
		}

		.stacked-cards-fanOut {
			padding-bottom: 40px;
		}

		.stacked-cards-fanOut li img {
			max-height: 200px;
		}

		.stacked-cards li {
			width: 300px;
			height: 300px;
		}

		@media (max-width: 767px) {
			.stacked-cards li {
				height: 180px;
			}
		}
		
		.source {
			margin: 25px auto;
		}

		.header {
			margin: 0px auto;
			padding: 25px 5px;
			background-color: #fff;
		}
		.header img {
			display: block;
			margin: 0 auto;
			max-width: 300px;
			height: auto;
		}

</style>
