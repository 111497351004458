<template>
  <div class="CloudHall_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        src="https://720yun.com/t/1evkt7dqp1l?scene_id=73707514"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2">
      <img :src="img" alt="">
    </div>
    <div class="layer2">
      <ul class="nav">
        <li>
          <img src="../../assets/PE_IMG/云会展/u153.png" alt="" />
          <h2 class="title">数字化运营</h2>
          <div class="room">
            <p>精细数字运营，沉淀优质买家</p>
            <p>参展数据统计，复盘商机淘金</p>
          </div>
        </li>
        <li>
          <img src="../../assets/PE_IMG/云会展/u157.png" alt="" />
          <h2 class="title">智能化匹配</h2>
          <div class="room">
            <p>精细数字运营，沉淀优质买家</p>
            <p>参展数据统计，复盘商机淘金</p>
          </div>
        </li>
        <li>
          <img src="../../assets/PE_IMG/云会展/u159.png" alt="" />
          <h2 class="title">便捷化参展</h2>
          <div class="room">
            <p>视频语音会客，足不出户参展</p>
            <p>产品多维展示，助力订单落地</p>
            <p>来访留言记录，把握每个商机</p>
          </div>
        </li>
        <li>
          <img src="../../assets/PE_IMG/云会展/u163.png" alt="" />
          <h2 class="title">多元化服务</h2>
          <div class="room">
            <p>全景、视频、图文展示</p>
            <p>线上线下多渠道推广</p>
            <p>全供应链贸易综合服务</p>
          </div>
        </li>
      </ul>
    </div>
		
		<div class="layer2" style="display: flex;align-items: center;justify-content: center;border-radius: 10px;">
			<video src="https://cloudvisitvr.oss-cn-shenzhen.aliyuncs.com/1634728690754sp.mp4" style="width: 100%;height: 200px;border-radius: 10px;" controls></video>
		</div>
		
		
    <div class="layer3">
      <h2 class="title">线下展会与云展会对比</h2>
      <div class="content">
        <div class="border">
          <div class="left">
            <h2 class="title">线下展会</h2>
            <p>数百万至数千万 绝大部分费用在展会举</p>
            <p>办前支付 广告费、展位费、专业</p>
            <p>会议费 需要花费数月准备，联系多方相关人员</p>
            <p>每家参展公司只能安排少数销售代表参展</p>
            <p>展位逐个参观，展位数量多较容易遗忘</p>
          </div>
          <div class="right">
            <h2 class="title">线下展会</h2>
            <p>免费借用平台，费用在展会举办后支付</p>
            <p>广告费、展位费、专业会议费</p>
            <p>广告费、展位费、功能付费、增值服务费</p>
            <p>公司全员参与，全面提升展会服务效率</p>
            <p>线上与线下相结合，线上为线下赋能</p>
          </div>
        </div>
      </div>
      <div class="tab">
        <ul class="tabs" ref="tabs">
          <li @click="change(0)">云展示</li>
          <li @click="change(1)">云洽谈</li>
          <li @click="change(2)">云直播</li>
        </ul>
        <div class="tabsContent">
          <div class="tabsContent1" v-show="currentTabs == 0">
            <img src="../../assets/PE_IMG/云会展/u173.svg" alt="" />
            <div class="contents2">
              <p>
                <b>线上展厅：</b
                ><span>全方位展示公司产品、技术实 力、企业文化等</span>
              </p>
              <p>
                <b>产品环物：</b
                ><span>多维度展示产品细节、外观结 构、工作原理等。</span>
              </p>
              <p>
                <b>真人讲解：</b
                ><span
                  >展厅内可加入真人讲解音频让浏
                  览者对公司和产品印象更加深刻</span
                >
              </p>
            </div>
          </div>
          <div class="tabsContent2" v-show="currentTabs == 1">
            <img src="../../assets/PE_IMG/云会展/u179.svg" alt="" />
            <p>
              系统加入了在线洽谈功能，可实现文字、图片、表情、短视频等富媒体消息收发；
              更采用了全球均可互通的音视频通话技术，在平台上即可实现网上“面对面”沟通，
              全面满足商家与买家的实时洽谈需求，随时谈生意。
            </p>
          </div>
          <div class="tabsContent3" v-show="currentTabs == 2">
            <img src="../../assets/PE_IMG/云会展/u182.svg" alt="" />
            <p>
              平台接入了直播功能，实现开幕式直播，大会直播，展商直播，线上发布会等多种直播形式，
              帮助客户全面展示企业形象和公司产品。
            </p>
          </div>
        </div>
      </div>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      currentTabs: 0,
      type: '',
      img: '',
      tv: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.getTdk()
    this.init1()
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '云会展'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    change (index) {
      this.currentTabs = index
      this.$refs.tabs.children[0].style = 'background:#d7d7d7'
      this.$refs.tabs.children[1].style = 'background:#d7d7d7'
      this.$refs.tabs.children[2].style = 'background:#d7d7d7'
      this.$refs.tabs.children[index].style = 'background:#f2f2f2'
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[2].type
      this.img = 'http://' + res.data.data.data[2].img_address.substring(13)
      this.tv = res.data.data.data[2].url_address

      console.log('nowType', this.type)

      if (this.type == 2) {
        
      } else if (this.type == 1) {
      }
    }
  }
}
</script>

<style lang="less" scoped>
.CloudHall_PE {
  width: 100%;
  .layer1_tv {
    width: 100%;
    height: 210px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .layer1 {
    width: 100%;

    img{
      width: 100%;
    }
  }
  .layer2 {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      li {
        width: 166px;
        height: 180px;
        background-color: #fffbfb;
        padding-top: 10px;
        box-shadow: 5px 5px 5px #88888869;
        margin-bottom: 15px;
        img {
          display: block;
          margin: 0 auto;
          height: 70px;
        }

        h2 {
          font-weight: 700;
          font-style: normal;
          color: rgba(0, 0, 0, 0.8);
          font-size: 13px;
          text-align: center;
        }
        .room {
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;

          p {
            font-weight: 700;
            font-style: normal;
            font-size: 10px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.498039215686275);
          }
        }
      }
    }
  }
  .layer3 {
    margin-top: 20px;
    margin-bottom: 10px;
    .title {
      font-size: 13px;
      font-weight: 700;
      text-align: center;
    }
    .content {
      width: 100%;
      height: 220px;
      background: url('../../assets/PE_IMG/云会展/u185.png');
      background-size: 100% 100%;
      padding-top: 30px;
      margin-top: 10px;

      .border {
        width: 350px;
        height: 180px;
        border: 2px solid #fff;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-top: 8px;

        .left,
        .right {
          position: relative;
          z-index: 1000;
          width: 160px;
        }
        .left {
          .title {
            text-align: right;
            position: absolute;
            top: -30px;
            right: 0;
          }
          p {
            font-size: 12px;
            font-weight: 700;
            text-align: right;
            padding-left: 30px;
          }
        }
        .right {
          .title {
            text-align: left;
            position: absolute;
            top: -30px;
            left: 0;
            color: #fff;
          }
          p {
            font-size: 12px;
            font-weight: 700;
            text-align: left;
            color: #fff;
            padding-right: 30px;
          }
        }
      }
    }
    .tab {
      width: 100%;
      padding-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 30px;
      .tabs {
        display: flex;
        li {
          height: 25px;
          line-height: 25px;
          text-align: center;
          width: 58px;
          background-color: #d7d7d7;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          margin-right: 15px;
          list-style: none;
          font-weight: 700;
          font-style: normal;
          font-size: 13px;
          color: rgba(2, 2, 2, 0.749019607843137);
          cursor: pointer;
          &:nth-child(1) {
            background-color: #f2f2f2;
          }
        }
      }
      .tabsContent {
        width: 100%;
        height: 370px;
        background: #fff;

        div {
          background-color: #f1f1f1;
          height: 100%;
        }
        .tabsContent1 {
          background-color: #f1f1f1;
          padding: 60px;
          padding-top: 30px;

          img {
            display: block;
            margin: 0 auto;
          }
          .contents2 {
            height: 150px;
            p {
              font-size: 13px;
              display: flex;
              margin-top: 15px;
              justify-content: space-between;
              b {
                width: 90px;
                display: block;
              }
              span {
                width: 230px;
                display: block;
              }
            }
          }
        }
        .tabsContent2 {
          background-color: #f1f1f1;
          padding-left: 60px;
          padding-right: 60px;
          padding-top: 30px;
          img {
            display: block;
            margin: 0 auto;
            margin-bottom: 40px;
          }
          p {
            font-size: 13px;
            line-height: 24px;
            color: #333333;
          }
        }
        .tabsContent3 {
          background-color: #f1f1f1;
          padding-left: 60px;
          padding-right: 60px;
          padding-top: 30px;
          img {
            display: block;
            margin: 0 auto;
            margin-bottom: 40px;
          }
          p {
            font-size: 13px;
            line-height: 24px;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
