<template>
  <div class="VirtualGalleryPC">
    <headers></headers>
    <div class="contents">
      <div class="layer1">
        <div class="bg" v-show="layer1Type == 2">
          <img :src="bg" alt="" />
        </div>
        <div class="tv" v-show="layer1Type == 1">
          <iframe
            id="u254_input"
            scrolling="auto"
            frameborder="1"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            :src="tv"
          ></iframe>
        </div>
      </div>
      <div class="layer2">
        <div class="content">
          <div class="left">
            <img
              :src="'http://' + this.array[0].img_address.substring(13)"
              alt=""
            />
          </div>
          <div class="right">
            <div class="title">
              {{ this.array[0].title }}
            </div>
            <div class="introduce">
              {{ this.array[0].desc }}
            </div>
          </div>
        </div>
        <div class="content2">
          <div class="left">
            <div class="title">
              {{ this.array[1].title }}
            </div>
            <div class="introduce">
              {{ this.array[1].desc }}
            </div>
          </div>
          <div class="right">
            <img
              :src="'http://' + this.array[1].img_address.substring(13)"
              alt=""
            />
          </div>
        </div>
        <div class="content">
          <div class="left">
            <img
              :src="'http://' + this.array[2].img_address.substring(13)"
              alt=""
            />
          </div>
          <div class="right">
            <div class="title">
              {{ this.array[2].title }}
            </div>
            <div class="introduce">
              {{ this.array[2].desc }}
            </div>
          </div>
        </div>
        <div class="content2">
          <div class="left">
            <div class="title">
              {{ this.array[3].title }}
            </div>
            <div class="introduce">
              {{ this.array[3].desc }}
            </div>
          </div>
          <div class="right">
            <img
              :src="'http://' + this.array[3].img_address.substring(13)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="layer3">
        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>行业应用</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Industry Application</h3>
        </div>
        <ul class="advantage">
          <li>
            <h3 class="advantage_title">加强购买决策</h3>
            <p class="advantage_content">
              在虚拟展厅中，可以让客户从各个角度详细了解产品，同时让客户更快更准确的做出购买决策。对于可配置的产品，客户可以在线上根据自己的需求进行调整，并且能够虚拟的体验自定义的产品。沉浸式的展厅体验不仅提高了客户浏览咨询的质量，还增加了产品的透明度并减少客户的存疑。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">不限时间空间</h3>
            <p class="advantage_content">
              与线下的展厅和活动不同，参与者不再局限于特定的地点、日期。虚拟展厅还节省了差旅的时间和成本。所有的参与者都能通过线上获取产品参数、公司简介、企业文化、办公环境等信息。同时，虚拟展厅增加了组织者的潜在客户和访问次数。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">增加情感依恋</h3>
            <p class="advantage_content">
              在虚拟空间中，产品的个性化可以增加用户对其结果的情感依赖。定制化的沉浸式体验，可以增加用户的参与度。由此产生的持久纽带，可以从配置的产品转移到品牌上，并增加用户在品牌场景中的总体停留时间。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">游戏化增加参与度</h3>
            <p class="advantage_content">
              为了让访问者更专注于您的产品，我们可以将您的产品和品牌的所有内容以一种既直观又吸引访客的方式呈现出来。事实证明，好玩的微互动或游戏化可以提高参与度。研究表明，通过游戏化的设计可以提高将近7倍的转化率。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">线上全网覆盖</h3>
            <p class="advantage_content">
              目标群体可以在全球范围内扩展，因为参与者的数量对于虚拟展厅是灵活的。就规模而言，虚拟展厅可以同时容纳几十万人访问。来自世界各地的参与者只需通过线上就可以访问定制化设计的虚拟空间。此外，虚拟展厅还可以实现线下展厅无法实现的功能：反复为参与者提供重复的体验。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">未来无限扩展</h3>
            <p class="advantage_content">
              从产品展示到品牌世界，虚拟空间可以自由的扩展和延伸。数字化的虚拟展厅可以很容易的随着项目需求而增长。包括虚拟展厅的空间和功能范围。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">节约运维成本</h3>
            <p class="advantage_content">
              与实体展馆相比，虚拟没有场地使用、物料制作及人力等运维支出，没有动辄十几万的费用支出，极大降低了建设成本，也提高了企业参展的意愿。
            </p>
          </li>
          <li>
            <h3 class="advantage_title">更多优势等您来探索！</h3>
          </li>
        </ul>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { selectIntroduce } from '../../api/virtualGallery'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      array: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init()
    this.init2()
    this.getTdk()
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '虚拟展厅'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[1].type
      setTimeout(() => {
        switch (res.data.data.data[1].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[1].img_address.substring(13)
            break
          case 1:
            this.tv = res.data.data.data[1].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      let res = await selectIntroduce({})

      this.array = res.data.data.data
      console.log('init2', this.array)
    }
  }
}
</script>

<style lang="less" scoped>
.VirtualGalleryPC {
  .contents {
    width: 100%;
    height: 100%;
    background: #f0f6ff;
    .layer1 {
      width: 100%;
      height: 500px;
      background: #f0f6ff;
      margin-bottom: 60px;
      .bg,
      .tv {
        width: 100%;
        height: 100%;
        iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
        .bgImg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .layer2 {
      margin-bottom: 82px;
      .content {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
        .left {
          width: 592px;
          height: 368px;
          img {
            display: block;
            width: 481px;
            height: 368px;
            float: right;
          }
        }
        .right {
          width: 590px;
          .title {
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            line-height: 28px;
          }
          .introduce {
            font-size: 16px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
          }
        }
      }
      .content2 {
        width: 1400px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 28px;
        .left {
          width: 590px;
          height: 368px;
          .title {
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            line-height: 28px;
            text-align: right;
          }
          .introduce {
            font-size: 16px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
            line-height: 28px;
            text-align: right;
          }
        }
        .right {
          width: 592px;
          height: 368px;
          img {
            display: block;
            width: 481px;
            height: 368px;
            float: left;
          }
        }
      }
    }
    .layer3 {
      padding-bottom: 14px;
      .title {
        width: 1076px;
        margin: 0 auto;
        padding-bottom: 30px;
        .title_main {
          display: flex;
          img {
            width: 416px;
            height: 20px;
            margin-top: 40px;
          }
          h2 {
            font-size: 51px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            margin-left: 20px;
            margin-right: 20px;
          }
        }
        .subtitle {
          font-size: 22px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #4c8dfc;
          text-align: center;
          letter-spacing: 8px;
          margin-top: 5px;
        }
      }
      .advantage {
        width: 1414px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 696px;
          height: 230px;
          padding-top: 44px;
          padding-left: 52px;
          padding-right: 49px;
          margin-bottom: 14px;
          list-style: none;
          &:nth-child(1) {
            background: url('../../assets/img/pc/VirtualGallery/1.png');
          }
          &:nth-child(2) {
            background: url('../../assets/img/pc/VirtualGallery/2.png');
          }
          &:nth-child(3) {
            background: url('../../assets/img/pc/VirtualGallery/3.png');
          }
          &:nth-child(4) {
            background: url('../../assets/img/pc/VirtualGallery/4.png');
          }
          &:nth-child(5) {
            background: url('../../assets/img/pc/VirtualGallery/5.png');
          }
          &:nth-child(6) {
            background: url('../../assets/img/pc/VirtualGallery/6.png');
          }
          &:nth-child(7) {
            background: url('../../assets/img/pc/VirtualGallery/7.png');
          }
          &:nth-child(8) {
            background: url('../../assets/img/pc/VirtualGallery/8.png');
            padding-top: 91px;
            padding-left: 53px;
          }
          .advantage_title {
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: 500;
            color: #4c8dfc;
            line-height: 41px;
            margin-bottom: 14px;
          }
          .advantage_content {
            font-size: 16px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
