import _fetch from '@/api/request.js'

//查询导航条内容以及每个页面的banner
function tdk (data) {
  return _fetch({
    url: '/api/tdk',
    method: 'post',
    data
  })
}


export { tdk }
