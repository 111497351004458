<template>
  <div class="headers">
    <div class="content">
      <div class="logo"></div>
      <ul class="nav">
        <li
          :class="currentIndex == index ? 'active' : ''"
          v-for="(item, index) in nav"
          :key="index"
          @click="to(index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getLocal, setLocal } from '../../utils/local.js'
export default {
  data () {
    return {
      nav: [
        {
          name: '首页',
          path: ''
        },
        {
          name: '虚拟展厅',
          path: ''
        },
        {
          name: '云会展',
          path: ''
        },
        {
          name: 'VR全景',
          path: ''
        },
        {
          name: 'AR应用',
          path: ''
        },
        {
          name: '视频制作',
          path: ''
        },
        {
          name: '新闻中心',
          path: ''
        },
        {
          name: '案例中心',
          path: ''
        },
        {
          name: '关于我们',
          path: ''
        }
      ],
      currentIndex:0
    }
  },
  mounted() {
    this.currentIndex=getLocal('PageIndex')
  },
  methods: {
    to (index) {
      switch (index) {
        case 0:
          this.$router.push('/HomePC')
          setLocal('PageIndex', index)
          break
        case 1:
          this.$router.push('/VirtualGalleryPC')
          setLocal('PageIndex', index)
          break
        case 2:
          this.$router.push('/CloudConventionPC')
          setLocal('PageIndex', index)
          break
        case 3:
          this.$router.push('/VR_PC')
          setLocal('PageIndex', index)
          break
        case 4:
          this.$router.push('/AR_PC')
          setLocal('PageIndex', index)
          break
        case 5:
          this.$router.push('/VideoProductionPC')
          setLocal('PageIndex', index)
          break
        case 6:
          this.$router.push('/NewsCenter')
          setLocal('PageIndex', index)
          break
        case 7:
          this.$router.push('/caseCenter')
          setLocal('PageIndex', index)
          break
        case 8:
          this.$router.push('/About_US')
          setLocal('PageIndex', index)
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.headers {
  width: 100%;
  height: 100px;
  line-height: 100%;
  background: #f7fbff;
  .content {
    width: 1400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    .logo {
      width: 226px;
      height: 80px;
      background: url('~@/assets/img/pc/Home/pclogo.4f407559@2x.png');
      background-size: 100% auto;
    }
    .nav {
      display: flex;
      li {
        list-style: none;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6c7ba8;
        margin-left: 57px;
        cursor: pointer;
      }
      .active {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: Medium;
        color: #4486ff;
      }
    }
  }
}
</style>
