import _fetch from '@/api/request.js'

//查询VR介绍部分
function selectIntroduce (data) {
  return _fetch({
    url: '/api/v1/vr',
    method: 'post',
    data
  })
}
//查询精选案例展厅
function selectPosters (data) {
  return _fetch({
    url: '/api/v1/vr/case',
    method: 'post',
    data
  })
}

export {selectIntroduce,selectPosters}
