import _fetch from '@/api/request.js'

//查询虚拟展厅介绍部分
function selectIntroduce (data) {
  return _fetch({
    url: '/api/v1/virtual',
    method: 'post',
    data
  })
}
//查询虚拟展厅海报部分
function selectPosters (data) {
  return _fetch({
    url: '/api/v1/poster',
    method: 'post',
    data
  })
}

export {selectIntroduce,selectPosters}
