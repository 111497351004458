<template>
  <div class="VR_PC">
    <headers></headers>
    <div class="layer1">
      <div class="bg" v-show="layer1Type == 2">
        <img class="bgImg" :src="bg" alt="" />
      </div>
      <div class="tv" v-show="layer1Type == 1">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="tv"
        ></iframe>
      </div>
    </div>
    <div class="layer2">
      <div class="title">
        <div class="title_main">
          <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
          <h2>案例展示</h2>
          <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
        </div>
        <h3 class="subtitle">Case Studies</h3>
      </div>
      <ul class="choose">
        <li
          :class="currentIndex == index ? 'active' : ''"
          v-for="(item, index) in choose"
          :key="index"
          @click="changeCase(index)"
        >
          {{ item }}
        </li>
      </ul>
      <div class="studies">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="this.case[currentIndex].url_address"
        ></iframe>
      </div>
      <div class="main">
        <div class="content1">
          <img
            class="left"
            :src="'http://' + Copywriting[0].img_address.substring(13)"
            alt=""
          />
          <div class="right">
            <h2 class="titles">{{ Copywriting[0].title }}</h2>
            <p>
              {{ Copywriting[0].desc }}
            </p>
          </div>
        </div>
        <div class="content2">
          <div class="left">
            <h2 class="titles">{{ Copywriting[1].title }}</h2>
            <p>
              {{ Copywriting[1].desc }}
            </p>
          </div>
          <img
            :src="'http://' + Copywriting[1].img_address.substring(13)"
            alt=""
          />
        </div>
        <div class="content1">
          <img
            class="left"
            :src="'http://' + Copywriting[2].img_address.substring(13)"
            alt=""
          />
          <div class="right">
            <h2 class="titles">{{ Copywriting[2].title }}</h2>
            <p>
              {{ Copywriting[2].desc }}
            </p>
          </div>
        </div>
        <div class="content2">
          <div class="left">
            <h2 class="titles">{{ Copywriting[3].title }}</h2>
            <p>
              {{ Copywriting[3].desc }}
            </p>
          </div>
          <img
            :src="'http://' + Copywriting[3].img_address.substring(13)"
            alt=""
          />
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { selectIntroduce, selectPosters } from '../../api/vr.js'
import { tdk } from '../../api/tdk'

export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      layer1Type: 0,
      tv: '',
      bg: '',
      choose: ['案例一', '案例二', '案例三', '案例四', '案例五'],
      case: [],
      currentIndex: 0,
      Copywriting: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init()
    this.init2()
    this.init3()
    this.getTdk()
  },
  methods: {
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[3].type
      setTimeout(() => {
        switch (res.data.data.data[3].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[3].img_address.substring(13)
            break
          case 1:
            this.tv = res.data.data.data[3].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      let res = await selectPosters({})
      console.log('init2', res)
      this.case = res.data.data.data
    },
    async init3 () {
      let res = await selectIntroduce({})
      this.Copywriting = res.data.data.data
      console.log('init3', this.Copywriting)
    },
    changeCase (index) {
      this.currentIndex = index
    },
    async getTdk () {
      let res = await tdk({
        name: 'VR全景'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    }
  }
}
</script>

<style lang="less" scoped>
.VR_PC {
  width: 100%;
  height: 100%;
  background: #f0f6ff;
  .layer1 {
    width: 100%;
    height: 500px;
    background: #f0f6ff;
    margin-bottom: 60px;

    .bg,
    .tv {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .layer2 {
    .title {
      width: 1076px;
      margin: 0 auto;
      margin-bottom: 14px;
      .title_main {
        display: flex;
        img {
          width: 416px;
          height: 20px;
          margin-top: 40px;
        }
        h2 {
          font-size: 51px;
          font-family: Alibaba PuHuiTi;
          font-weight: bold;
          color: #4c8dfc;
          margin-left: 20px;
          margin-right: 20px;
        }
      }
      .subtitle {
        font-size: 22px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #4c8dfc;
        text-align: center;
        letter-spacing: 8px;
        margin-top: 5px;
      }
    }
    .choose {
      width: 720px;
      margin: 0 auto;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      li {
        list-style: none;
        width: 120px;
        height: 42px;
        line-height: 42px;
        background: linear-gradient(0deg, #f7faff, #e0ebff, #f9fbff);
        border: 1px solid #dbe7ff;
        box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
        border-radius: 21px;
        text-align: center;
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #6c7ba8;
        cursor: pointer;
      }
      .active {
        background: linear-gradient(0deg, #abd4ff, #76acff, #4783f3);
        border: 1px solid #b9d8ff;
        box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
        border-radius: 21px;
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .studies {
      width: 1200px;
      height: 600px;
      background: #000;
      margin: 0 auto;
      margin-bottom: 50px;
      border-radius: 20px;
      overflow: hidden;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
    .main {
      width: 1400px;
      margin: 0 auto;
      padding-bottom: 30px;
      .content2 {
        display: flex;
        justify-content: space-between;
        padding: 0 100px;
        margin-bottom: 20px;
        .left {
          width: 550px;
          height: 300px;
          .titles {
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            line-height: 28px;
            margin-top: 56px;
            margin-bottom: 20px;
            text-align: right;
          }
          p {
            font-size: 18px;
            color: #6d7382;
            line-height: 28px;
            text-align: right;
          }
        }
        .right {
          width: 550px;
          height: 300px;
        }
      }
      .content1 {
        display: flex;
        justify-content: space-between;
        padding: 0 100px;
        margin-bottom: 20px;
        .left {
          width: 550px;
          height: 300px;
        }
        .right {
          width: 550px;
          height: 300px;
          .titles {
            font-size: 26px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            line-height: 28px;
            margin-top: 56px;
            margin-bottom: 20px;
          }
          p {
            font-size: 18px;
            color: #6d7382;
            line-height: 28px;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
