<template>
  <div class="VideoProductionPC">
    <headers></headers>
    <div class="layer1">
      <div class="bg" v-show="layer1Type == 2">
        <img class="bgImg" :src="bg" alt="" />
      </div>
      <div class="tv" v-show="layer1Type == 1">
        <iframe
          id="u254_input"
          scrolling="auto"
          frameborder="1"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          :src="tv"
        ></iframe>
      </div>
    </div>
    <div class="layer2">
      <div class="content">
        <ul class="nav">
          <li>
            <img
              src="../../assets/img/pc/VideoProductionPC/定制@2x.png"
              alt=""
            />
            <span>私人订制</span>
          </li>
          <li>
            <img
              src="../../assets/img/pc/VideoProductionPC/椭圆 1 拷贝@2x.png"
              alt=""
            />
            <span>原创设计</span>
          </li>
          <li>
            <img
              src="../../assets/img/pc/VideoProductionPC/椭圆 1 拷贝 2@2x.png"
              alt=""
            />
            <span>快速出稿</span>
          </li>
          <li>
            <img
              src="../../assets/img/pc/VideoProductionPC/椭圆 1 拷贝 3@2x.png"
              alt=""
            />
            <span>低价高质</span>
          </li>
        </ul>
        <div class="title">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>多媒体应用</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Multimedia Application</h3>
          <h3 class="subtitle2">
            超越想象的视觉创新，打破感觉界限的全领域应用,在市场竞争的浪潮中脱颖而出
          </h3>
        </div>
        <ul class="choose">
          <li
            v-for="(item, index) in arr"
            :key="index"
            :class="currentIndex == index ? 'active' : ''"
            @click="chooseChange(index)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="videoBox">
          <video
            v-if="currentIndex == 0"
            id="video1"
            controls
            :poster="imgArr[0]"
            :key="0"
          >
            <source :src="videoArr[0]" type="video/mp4" />
          </video>
          <video
            v-if="currentIndex == 1"
            id="video1"
            controls
            :poster="imgArr[1]"
            :key="1"
          >
            <source :src="videoArr[1]" type="video/mp4" />
          </video>
          <video
            v-if="currentIndex == 2"
            id="video1"
            controls
            :poster="imgArr[2]"
            :key="2"
          >
            <source :src="videoArr[2]" type="video/mp4" />
          </video>
          <video
            v-if="currentIndex == 3"
            id="video1"
            controls
            :poster="imgArr[3]"
            :key="3"
          >
            <source :src="videoArr[3]" type="video/mp4" />
          </video>
          <video
            v-if="currentIndex == 4"
            id="video1"
            controls
            :poster="imgArr[4]"
            :key="4"
          >
            <source :src="videoArr[4]" type="video/mp4" />
          </video>
          <video
            v-if="currentIndex == 5"
            id="video1"
            controls
            :poster="imgArr[5]"
            :key="5"
          >
            <source :src="videoArr[5]" type="video/mp4" />
          </video>
        </div>
        <div class="title" style="margin-bottom:10px">
          <div class="title_main">
            <img src="@/assets/img/pc/Home/图层 3 拷贝@2x.png" alt="" />
            <h2>精选案例</h2>
            <img src="@/assets/img/pc/Home/图层 3@2x.png" alt="" />
          </div>
          <h3 class="subtitle">Multimedia Application</h3>
        </div>
        <!-- <ul class="application">
          <li v-for="(item, index) in TvArr" :key="index">
            <video
              :src="'http://' + item.tv_address.substring(13)"
              controls
              :poster="'http://' + item.img_address.substring(13)"
            ></video>
          </li>
        </ul> -->
				<div class="newVideo">
					<div class="newVideoBox" v-for="(item, index) in TvArr" :key="index" @click="goVideoPage(index)">
						<img :src="'http://' + item.img_address.substring(13)" class="newVideoImg">
					</div>
				</div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '@/components/pc/footers.vue'
import headers from '@/components/pc/headers.vue'
import { selectNavBarAndBanner } from '@/api/home'
import { select } from '../../api/videoproduction.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    headers,
    footers
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      currentIndex: 0,
      layer1Type: 0,
      tv: '',
      bg: '',
      arr: [
        {
          name: '宣传片'
        },
        {
          name: 'MG动画'
        },
        {
          name: '三维动画'
        },
        {
          name: '短视频'
        },
        {
          name: '产品视频'
        },
        {
          name: '广告视频'
        }
      ],
      imgArr: [
        'http://www.yunshikongjian.com/[宣传片]品质龙岗.jpg',
        'http://www.yunshikongjian.com/[MG动画]工业动画视频.jpg',
        'http://www.yunshikongjian.com/[三维视频]cpu三维建模视频.jpg',
        'http://www.yunshikongjian.com/[短视频]公司广告短视频.png',
        'http://www.yunshikongjian.com/[产品视频]高仙机器人创意广告.jpg',
        'http://www.yunshikongjian.com/[广告视频]荣耀9青春版.jpg'
      ],
      videoArr: [
        'http://www.yunshikongjian.com/[宣传片]品质龙岗.mp4',
        'http://www.yunshikongjian.com/[MG动画]工业动画视频.mp4',
        'http://www.yunshikongjian.com/[三维视频]cpu三维建模视频.m4v',
        'http://www.yunshikongjian.com/[短视频]公司广告短视频.mp4',
        'http://www.yunshikongjian.com/[产品视频]高仙机器人创意广告.mp4',
        'http://www.yunshikongjian.com/[广告视频]荣耀9青春版.mp4'
      ],
      TvArr: [],
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.init()
    this.init2()
    this.getTdk()
  },
  methods: {
    async init () {
      let res = await selectNavBarAndBanner({})
      this.layer1Type = res.data.data.data[5].type
      console.log('this.layer1Type', this.layer1Type)
      setTimeout(() => {
        switch (res.data.data.data[5].type) {
          case 2:
            this.bg =
              'http://' + res.data.data.data[5].img_address.substring(13)
            console.log('当前图片' + this.bg)
            break
          case 1:
            this.tv = res.data.data.data[5].url_address
            break

          default:
            break
        }
      }, 1000)
    },
    async init2 () {
      let res = await select({})
      // console.log('init2', res.data.data.data)
      res.data.data.data.forEach(item => {
        item.list.forEach(item2 => {
          this.TvArr.push(item2.data)
          // console.log(item2.data);
        })
      })
      console.log(this.TvArr)
    },
    chooseChange (index) {
      this.currentIndex = index
    },
    async getTdk () {
      let res = await tdk({
        name: '视频制作'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
	
	
	//跳转视频地址
	goVideoPage(index){
		console.log(this.TvArr[index])
		window.open('http://' + (this.TvArr[index].tv_address).substring(13),'_blank');
	}
  }
}
</script>

<style lang="less" scoped>
.VideoProductionPC {
  width: 100%;
  height: 100%;
  background: #f0f6ff;
  .layer1 {
    width: 100%;
    height: 600px;
    background: #f0f6ff;
    margin-bottom: 60px;
    .bg,
    .tv {
      width: 100%;
      height: 600px;
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
      .bgImg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .layer2 {
    .content {
      width: 1400px;
      margin: 0 auto;
      .nav {
        display: flex;
        justify-content: space-between;
        margin-bottom: 80px;
        li {
          width: 138px;
          list-style: none;
          img {
            display: block;
          }
          span {
            font-size: 22px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #4486ff;
            line-height: 37px;
          }
          text-align: center;
        }
      }
      .title {
        width: 1130px;
        margin: 0 auto;
        margin-bottom: 45px;
        .title_main {
          display: flex;
          img {
            width: 416px;
            height: 20px;
            margin-top: 40px;
          }
          h2 {
            font-size: 51px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #4c8dfc;
            margin-left: 20px;
            margin-right: 20px;
          }
        }
        .subtitle {
          font-size: 22px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #4c8dfc;
          text-align: center;
          letter-spacing: 8px;
          margin-top: 5px;
        }
        .subtitle2 {
          font-size: 20px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: #6c7ba8;
          text-align: center;
          margin-top: 10px;
        }
      }
      .choose {
        width: 868px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        li {
          text-align: center;
          list-style: none;
          width: 120px;
          height: 42px;
          background: linear-gradient(0deg, #f7faff, #e0ebff, #f9fbff);
          border: 1px solid #dbe7ff;
          box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
          border-radius: 21px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #6c7ba8;
          line-height: 41px;
          cursor: pointer;
        }
        .active {
          background: linear-gradient(0deg, #abd4ff, #76acff, #4783f3);
          border: 1px solid #b9d8ff;
          box-shadow: 0px 5px 7px 0px rgba(114, 129, 159, 0.1);
          border-radius: 21px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 500;
          color: #ffffff;
          line-height: 41px;
        }
      }
      .videoBox {
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 90px;
        border-radius: 20px;
        overflow: hidden;
        #video1 {
          width: 100%;
        }
      }
      .application {
        list-style: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 43px;
        &::after {
          content: '';
          width: 450px;
        }
        li {
          width: 450px;
          height: 280px;
          margin-bottom: 26px;
          border-radius: 20px;
          overflow: hidden;
          video {
            width: 100%;
            height: 100%;
            background-color: gray;
            object-fit: fill;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.newVideo{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}


.newVideoBox{
	display: flex;
	justify-content: flex-start;
	margin-right: 25px;
	margin-bottom: 26px;
	cursor: pointer;
}

.newVideoImg{
	width: 450px;
	height: 280px;
	border-radius: 20px;
}

.newVideoBox:nth-child(3n){
	margin-right: 0;
}
</style>
