<template>
  <div class="NewsDetails_PE">
    <navbar></navbar>
    <div class="layer1_tv" v-show="type == 1">
      <iframe
        id="u93_input"
        scrolling="auto"
        frameborder="1"
        webkitallowfullscreen=""
        mozallowfullscreen=""
        allowfullscreen=""
        :src="tv"
      ></iframe>
    </div>
    <div class="layer1" v-show="type == 2"></div>
    <div class="layer2">
      <div class="title">数字虚拟展厅，企业展示推广新利器</div>
      <div class="state">
        <div class="publisher">深圳市云视空间科技有限公司</div>
        <div class="date">2021.06.11</div>
      </div>
      <div class="article">
        <img :src="newsImg" alt="" />
        <div id="contents"></div>
      </div>
    </div>
    <footerpe></footerpe>
  </div>
</template>

<script>
import navbar from '../../components/pe/navbar.vue'
import footerpe from '../../components/pe/footerPe.vue'
import { selectNavBarAndBanner } from '../../api/home.js'
import { selectNewsDetails } from '../../api/news.js'
import { tdk } from '../../api/tdk'
export default {
  components: {
    navbar,
    footerpe
  },
  metaInfo () {
    return {
      title: this.tdks.t,
      meta: [
        {
          name: 'Keywords',
          content: this.tdks.k
        },
        {
          name: 'description',
          content: this.tdks.d
        }
      ]
    }
  },
  data () {
    return {
      nowId: '',
      type: '',
      img: '',
      tv: '',
      newsTitle: '',
      newsContent: '',
      newsImg: '',
      newCreateData: '',
      // tdk信息
      tdks: ''
    }
  },
  mounted () {
    this.nowId = parseInt(window.localStorage.getItem('current_id'))
    this.init1()
    this.init2()
    this.getTdk()
  },
  methods: {
    async getTdk () {
      let res = await tdk({
        name: '新闻中心'
      })
      console.log('tdk返回:', res)
      this.tdks = res.data.data
    },
    async init1 () {
      let res = await selectNavBarAndBanner({})
      console.log('init1', res)
      this.type = res.data.data.data[6].type
      this.img = 'http://' + res.data.data.data[6].img_address.substring(13)
      this.tv = res.data.data.data[6].url_address
      console.log('nowType', this.type)
      if (this.type == 2) {
        document.getElementsByClassName('layer1')[0].style.background =
          'url(' + this.img + ') no-repeat' //通过js控制改变行内样式
        document.getElementsByClassName('layer1')[0].style.backgroundSize =
          '100% 210px'
      } else if (this.type == 1) {
      }
    },
    async init2 () {
      let res = await selectNewsDetails({
        id: this.nowId
      })
      console.log('init2', res)
      this.newsTitle = res.data.data.title
      this.newsContent = res.data.data.desc
      this.newCreateData = res.data.data.create_time
      this.newsImg = 'http://' + res.data.data.img.substring(13)
      document.getElementById('contents').innerHTML = res.data.data.desc
    }
  }
}
</script>

<style lang="less" scoped>
.NewsDetails_PE {
  width: 100%;
  .layer1_tv {
    width: 100%;
    height: 210px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .layer1 {
    width: 100%;
    height: 210px;
  }
  .layer2 {
    padding-left: 10px;
    padding-right: 10px;
    .title {
      font-family: '微软雅黑 Bold', '微软雅黑', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 13px;
      padding-top: 20px;
      padding-bottom: 15px;
      text-align: center;
    }
    .state {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .publisher {
        font-size: 12px;
      }
      .date {
        font-size: 12px;
      }
    }
    .article {
      padding-bottom: 20px;
      img {
        width: 100%;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      #contents {
        font-family: '微软雅黑', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        text-align: justify;
      }

      p {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
