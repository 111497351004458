import _fetch from '@/api/request.js'

//查询导航条内容以及每个页面的banner
function selectNavBarAndBanner (data) {
  return _fetch({
    url: '/api/v1/home',
    method: 'post',
    data
  })
}

//查询首页核心业务信息
function selectCoreBusiness (data) {
  return _fetch({
    url: '/api/v1/core',
    method: 'post',
    data
  })
}
//查询首页合作伙伴信息
function selectPartners (data) {
  return _fetch({
    url: '/api/v1/partner',
    method: 'post',
    data
  })
}

export { selectNavBarAndBanner,selectCoreBusiness,selectPartners }
