import _fetch from '@/api/request.js'

//查询所有新闻
function select (data) {
  return _fetch({
    url: '/api/v1/news/info',
    method: 'post',
    data
  })
}
//查询新闻详情
function selectNewsDetails (data) {
  return _fetch({
    url: '/api/v1/news/desc',
    method: 'post',
    data
  })
}

function selectTitle(data){
  return _fetch({
    url: '/api/v1/news/title',
    method: 'post',
    data
  })
}


export {select,selectNewsDetails,selectTitle}
